import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Col, Row } from 'antd';
import BasicContainer from '@copilot/common/components/containers/basic';
import { OnboardMessage } from '@copilot/data/requests/models';
import { Config } from '@copilot/common/config';
import {
	DEFAULT_TIME_UNITS,
	LONGER_TIME_UNITS,
	MessageType,
	Template,
	REQUEST_MESSAGE_CHAR_LIMIT,
} from './const';
import MessageHeader from './header';
import MessageEditorWithTemplates from '@copilot/common/components/editors/message/withTemplates';
import MessageEditor, { defaultWarningText } from '@copilot/common/components/editors/message';
import { NodeTimerPeriodEnum } from '@copilot/common/store/models/const/enum';
import InitiateTimingEditor from '@copilot/common/components/editors/message/initiateTimingEditor';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { complianceMessage } from '../../../constant/strings';

/**
 * Retrieve correct warning for editing a message in a messaging sequence
 * @param messageType
 * @param isNewMessage
 */
function WarningMessage({
	messageType,
	useNewMessageWarning,
}: {
	messageType: MessageType;
	useNewMessageWarning: boolean;
}) {
	if (!useNewMessageWarning)
		return <p>This edit will affect all future automated sending of this message.</p>;

	switch (messageType) {
		case MessageType.Nurture:
			return (
				<p>
					You are adding another message to the end of the automation. Any prospects who
					have completed the automation already will <strong>NOT</strong> receive this
					message
				</p>
			);
		case MessageType.FollowUp:
			return <p>{defaultWarningText}</p>;
		default:
			return UtilityFunctions.assertUnreachable(messageType);
	}
}

interface IMessageDisplayProps {
	messageTitle: string;
	message: OnboardMessage;
	messageType: MessageType;
	charLimit?: number;
	templates?: Template[];
	isMessageHidden: boolean;
	isEditable: boolean;
	isEditing: boolean;
	isDeletable: boolean;
	hideTiming: boolean;
	hideWarning: boolean;
	hasInitiateTimer: boolean;
	hideTerminationSave: boolean;
	prevNodeId?: string;
	campaignId?: string;
	useNewMessageWarning: boolean;
	toggleEdit: () => void;
	handleSave: (message: OnboardMessage) => void;
	handleDelete: (message: OnboardMessage) => void;
	handleStartTimingSave: (message: OnboardMessage) => void;
	placeholderSubtitle: { text: string; color: string };
}

function MessageDisplay({
	messageTitle,
	message,
	messageType,
	charLimit,
	templates,
	isMessageHidden,
	isEditable,
	isEditing,
	isDeletable,
	hideTiming,
	hideWarning,
	hasInitiateTimer,
	hideTerminationSave,
	prevNodeId,
	campaignId,
	useNewMessageWarning,
	toggleEdit,
	handleSave,
	handleDelete,
	handleStartTimingSave,
	placeholderSubtitle,
}: IMessageDisplayProps) {
	const [selectedTemplate, setSelectedTemplate] = useState<string>('');

	return (
		<>
			{hasInitiateTimer && hideTiming && (
				//TODO: [Nurture] Only usecase now is for nurture campaign but think of ways to pass header and description for other campaigns
				<InitiateTimingEditor
					header="Nurture Start Timing"
					description="This is the minimum amount of time a prospect will wait before receiving their first nurture message"
					onSave={handleStartTimingSave}
					message={message}
					isEditable={isEditable}
				/>
			)}
			<br />
			{!isMessageHidden && (
				<MessageHeader
					messageTitle={messageTitle}
					message={message}
					isEditable={isEditable}
					isDeletable={isDeletable}
					onEditClick={toggleEdit}
					onDeleteClick={handleDelete}
				/>
			)}
			{!isEditing && (
				<Row>
					<Col
						span={16}
						style={{ color: placeholderSubtitle.color, whiteSpace: 'break-spaces' }}
					>
						<p>{placeholderSubtitle.text}</p>
					</Col>
				</Row>
			)}
			<Row>
				<Col span={16}>
					{!isMessageHidden &&
						isEditing &&
						(templates ? (
							<MessageEditorWithTemplates
								message={message}
								templates={templates}
								selectedTemplate={selectedTemplate}
								updateSelectedTemplate={setSelectedTemplate}
								hideTiming={hideTiming}
								recommendedPeriod={NodeTimerPeriodEnum.Days}
								recommendedTime={15}
								timeUnits={LONGER_TIME_UNITS}
								hideWarning={hideWarning}
								warningMessage={
									<WarningMessage
										messageType={messageType}
										useNewMessageWarning={useNewMessageWarning}
									/>
								}
								hideTerminationSave={hideTerminationSave}
								prevNodeId={prevNodeId}
								onCancel={toggleEdit}
								onSave={handleSave}
							/>
						) : (
							<MessageEditor
								messageTitle={messageTitle}
								message={message}
								charLimit={charLimit}
								hideTiming={hideTiming}
								timeUnits={DEFAULT_TIME_UNITS}
								hideWarning={hideWarning}
								warningMessage={
									<WarningMessage
										messageType={messageType}
										useNewMessageWarning={useNewMessageWarning}
									/>
								}
								hideTerminationSave={hideTerminationSave}
								campaignId={campaignId}
								onCancel={toggleEdit}
								onSave={handleSave}
							/>
						))}
				</Col>
			</Row>
		</>
	);
}

interface MessageSettingsProps {
	messages: OnboardMessage[];
	isEditable?: boolean;
	messageOrder: string[];
	onSave: (idx: number, notification?: string) => (message: OnboardMessage) => void;
	onDelete: (message: OnboardMessage) => void;
	hasInitiateTimer: boolean;
	messageType?: MessageType;
	addMessage: () => void;
	getIsMessageHidden: (idx: number) => boolean;
	getIsMessageDeletable: (idx: number) => boolean;
	initialMessageIndex: number;
	campaignId?: string;
	templates?: Template[];
}

/**
 * Message Settings Page
 * @param {OnboardMessage[]} messages available messages to display and edit
 * @param {boolean} [isEditable] whether messages are editable or not
 * @param {string[]} messageOrder name of messages in order
 * @callback onSave called on save
 * @callback onDelete called on delete
 * @param {boolean} hasInitiateTimer whether settings needs to show initiate message timing editor
 * @param {MessageType} [messageType] messageType used to display what type of message we are adding
 * @callback addMessage called on adding new empty message
 * @callback getIsMessageHidden called to check if a message is to be hidden
 * @callback getIsMessageDeletable called to check if a message is deletable
 * @param {number} initialMessageIndex index of initial displayed message
 * @param {string} [campaignId] campaignId for saving message as quick response
 * @param {Template[]} [templates] a list of templates for messages
 */
//TODO: CPD-2955
function MessageSettings({
	messages,
	isEditable = false,
	messageOrder,
	onSave,
	onDelete,
	hasInitiateTimer,
	messageType = MessageType.FollowUp,
	addMessage,
	getIsMessageHidden,
	getIsMessageDeletable,
	initialMessageIndex,
	campaignId,
	templates,
}: MessageSettingsProps) {
	const defaultEditStates = useMemo(
		() =>
			messageOrder.reduce<{ [i: number]: boolean }>((acc, m, idx) => {
				acc[idx] = false;
				return acc;
			}, {}),
		[messageOrder]
	);

	const [editStates, setEditStates] = useState<{ [i: number]: boolean }>(defaultEditStates);
	const [numNewMessages, setNumNewMessages] = useState<number>(0);
	const [isEmptyMessage, setIsEmptyMessage] = useState<boolean>(false);

	const toggleEdit = React.useCallback((idx: number) => {
		setEditStates((states) => ({ ...states, [idx]: !states[idx] }));
	}, []);

	const handleSave = (idx: number) => (message: typeof messages[0]) => {
		const saveMessage = onSave(idx);
		saveMessage(message);
		toggleEdit(idx);
	};

	const handleStartTimingSave = (idx: number) => (message: typeof messages[0]) => {
		const saveMessage = onSave(idx, 'Start Timing Saved');
		saveMessage(message);
	};

	useEffect(() => {
		setEditStates(defaultEditStates);
	}, [messages, defaultEditStates]);

	useEffect(() => {
		const lastMessage = messages[messages.length - 1];
		setIsEmptyMessage(lastMessage && !lastMessage.text);
	}, [messages]);

	useEffect(() => {
		if (!isEditable)
			setEditStates((s) =>
				Object.keys(s).reduce((obj, key) => ({ ...obj, [key]: false }), {})
			);
	}, [isEditable]);

	// Increment number of new messages & append a new message to the list
	const handleAdd = useCallback(() => {
		setNumNewMessages((numMessages) => numMessages + 1);
		addMessage();
	}, [addMessage]);

	// If the message has been added during this session, decrement the number of new messages, & delete the message
	const handleDelete = useCallback(
		(message: OnboardMessage, index: number) => {
			if (numNewMessages >= messages.length - index)
				setNumNewMessages((numMessages) => numMessages - 1);
			onDelete(message);
		},
		[onDelete]
	);

	const addNewMessageButton = useMemo(() => {
		if (!messages || messages.length < messageOrder.length) {
			return (
				<Button type="primary" onClick={handleAdd} disabled={!isEditable || isEmptyMessage}>
					{`Add ${messageType} message`}
				</Button>
			);
		} else return null;
	}, [messages, messageOrder, isEmptyMessage, messageType, isEditable, handleAdd]);

	const messageTitleMap = new Map([
		['Connection request message', 'The first message a prospect will see'],
		['Second message', 'Sent after the connection request message is accepted.'],
	]);

	const messageSubtitlePlaceholder = (message: OnboardMessage, messageTitle: string) => {
		if (message.text.length === 0 && messageTitleMap.has(messageTitle)) {
			return {
				text: messageTitleMap.get(messageTitle)!,
				color: 'Grey',
			};
		}
		return {
			text: message.text,
			color: 'Black',
		};
	};

	return (
		<BasicContainer bordered={false}>
			<BasicContainer.Content style={{ padding: '0px' }}>
				<h2>Edit your messages</h2>
				<Alert
					message={
						!Config.isAgency
							? complianceMessage
							: 'IMPORTANT: If you change your connection request message it may look like your prospects are getting your old connection request message, but these are the previously sent pending invitations that are being accepted'
					}
					type="warning"
					showIcon
					style={{ width: 'fit-content' }}
				/>
				<br />
				{messages.map((message, idx) => (
					<MessageDisplay
						key={idx}
						messageTitle={`${messageOrder[idx]} message`}
						message={message}
						messageType={messageType}
						charLimit={idx === 0 ? REQUEST_MESSAGE_CHAR_LIMIT : undefined}
						templates={templates}
						isMessageHidden={getIsMessageHidden(idx)}
						isEditable={isEditable}
						isEditing={editStates[idx]}
						isDeletable={getIsMessageDeletable(idx)}
						hideTiming={idx === initialMessageIndex}
						hideWarning={idx < 2}
						hasInitiateTimer={hasInitiateTimer}
						hideTerminationSave={messageType === MessageType.Nurture}
						prevNodeId={messages[idx - 1]?.nodeId}
						campaignId={campaignId}
						useNewMessageWarning={idx === messages.length - 1 && numNewMessages > 0}
						toggleEdit={() => toggleEdit(idx)}
						handleSave={handleSave(idx)}
						handleDelete={(msg) => handleDelete(msg, idx)}
						handleStartTimingSave={handleStartTimingSave(idx)}
						placeholderSubtitle={messageSubtitlePlaceholder(
							message,
							`${messageOrder[idx]} message`
						)}
					/>
				))}
				<br />
				{addNewMessageButton}
			</BasicContainer.Content>
		</BasicContainer>
	);
}

export default MessageSettings;
