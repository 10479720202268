import * as React from 'react';
import BubbleCheckBox from './bubblecheckbox';

export interface BubbleCheckboxGroupProps {
	values: CheckboxObj[];
	onChange: (titles: CheckboxObj[]) => void;
	showCheckAll?: boolean;
}

export class CheckboxObj {
	public label: string | null;
	public value: string;
	public isChecked: boolean;

	constructor(value: string, isChecked = false, label: string | null = null) {
		this.value = value;
		this.label = label;
		this.isChecked = isChecked;
	}
}

interface BubbleCheckboxGroupState {
	values: CheckboxObj[];
	checkAll: boolean;
}

class BubbleCheckboxGroup extends React.Component<
	BubbleCheckboxGroupProps,
	BubbleCheckboxGroupState
> {
	state: BubbleCheckboxGroupState = { values: [], checkAll: false };
	constructor(props: BubbleCheckboxGroupProps) {
		super(props);
		this.state = {
			values: props.values,
			checkAll: false,
		};
		this.handleCheckChildElement = this.handleCheckChildElement.bind(this);
		this.handleAllChecked = this.handleAllChecked.bind(this);
	}

	componentDidUpdate(prevProps: BubbleCheckboxGroupProps) {
		if (prevProps.values != this.props.values) {
			this.setState({ values: this.props.values });
		}
	}

	handleAllChecked() {
		const { values } = this.state;
		values.forEach((value) => (value.isChecked = !this.state.checkAll));
		this.props.onChange(values);
		this.setState({ checkAll: !this.state.checkAll });
	}

	handleCheckChildElement(checkedValue: any) {
		const { values } = this.state;
		let allChecked = true;
		values.forEach((value) => {
			if (value.value === checkedValue) value.isChecked = !value.isChecked;
			if (!value.isChecked) allChecked = false;
		});
		this.props.onChange(values);
		this.setState({ values, checkAll: allChecked });
	}

	render() {
		return (
			<div className="bubble-checkbox-group">
				{this.props.showCheckAll && (
					<>
						<BubbleCheckBox
							handleCheckChildElement={this.handleAllChecked}
							isChecked={this.state.checkAll}
							label="Select All"
							id="check-all"
							value="SelectAll"
						/>
						<br />
					</>
				)}
				{this.state.values.map((checkbox) => (
					<BubbleCheckBox
						handleCheckChildElement={this.handleCheckChildElement}
						isChecked={checkbox.isChecked}
						id={checkbox.value}
						value={checkbox.value}
						label={checkbox.label}
						key={checkbox.value}
					/>
				))}
			</div>
		);
	}
}

export default BubbleCheckboxGroup;
