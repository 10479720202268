import React, { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { Spin, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectAsync, useFetch } from '@copilot/common/hooks/common';
import { useHistory, useParams } from 'react-router';
import { DarkHeaderWithSupportLinks } from '@copilot/common/components/menus/header/Logo';
import NurtureWelcome from '@copilot/common/components/wizard/steps/nurture/nurtureWelcome';
import InfoSection from '@copilot/common/components/infoSection';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import FullPageWizard from '@copilot/common/components/wizard/fullPage';
import {
	WizardStepNode,
	NurtureStepTitles,
	WizardStepHeaderText,
	WizardInfoSectionText,
} from './const';
import {
	NurtureListOption,
	NurtureListWizardNavText,
	NurtureListNumConnections,
	numConnectionsLabel,
} from '@copilot/common/components/wizard/steps/nurture/const';
import { NurtureListOptionType } from '@copilot/common/store/models/const/enum';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import { NurtureOnboardManager } from '@copilot/data';
import { NurtureOnboardModel } from '@copilot/data/responses/models/nurtureOnboard';
import {
	getNurtureOnboard,
	fetchNurtureOnboard,
} from '@copilot/common/pages/wizard/nurtureOnboard/data';
import NurtureList from '@copilot/common/components/wizard/steps/nurture/nurtureList';
import NurtureMessaging from '@copilot/common/components/wizard/steps/nurture/nurtureMessaging';
import NurtureReview from '@copilot/common/components/wizard/steps/nurture/nurtureReview';
import { getPluralizedEnding } from '@copilot/common/utils';
import { OnboardMessage } from '@copilot/data/requests/models';
import notificationManager from '@copilot/common/utils/notificationManager';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { appInsights } from '@copilot/common/components/snippets/applicationInsights';
import { Config } from '@copilot/common/config';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { useIntercom } from 'react-use-intercom';
import { CampaignMemberSelectors } from '@copilot/common/store/selectors/campaignMember';
import WizardStepNavButtonFooter from '@copilot/common/components/wizard/steps/navButton';
import { getAllOrgTags } from '../../organizationDashboard/tags/data/selectors';

const OnboardSubmitObject = { step: WizardStepNode.NURTURE_COMPLETE };

const isMessagesValid = (messages: OnboardMessage[]) => messages.length && messages[0].text.length;

interface INurtureOnboardProps {
	onboard: NurtureOnboardModel;
	activeMember: IOrganizationMember;
	numConnections: NurtureListNumConnections;
	availableTags: string[];
	updateOnboard: (updates: Partial<NurtureOnboardModel>) => void;
}

const NurtureOnboard: React.FC<INurtureOnboardProps> = (props) => {
	const { onboard, activeMember, numConnections, availableTags, updateOnboard } = props;
	const { trackEvent } = useIntercom();
	const [isSaved, setIsSaved] = useState<boolean>(true);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const isInitialRender = useRef(true);
	const dispatch = useDispatch();
	const history = useHistory();

	const onSaveSuccess = (saveStatus: boolean, callback?: () => void) => {
		setIsSaved(saveStatus);
		if (callback) callback();
	};

	const onSaveError = () => {
		setIsSubmitting(false);
		notificationManager.showErrorNotification({
			message: 'Save Failed',
			description: 'Please try again',
		});
	};

	const onSave = useCallback(
		(
			saveStatus: boolean,
			modifications?: Partial<NurtureOnboardModel>,
			onSuccess?: () => void
		) => {
			isInitialRender.current = true; // prevents save status from changing back to unsaved
			dispatch(
				fetchNurtureOnboard(
					NurtureOnboardManager.updateNurtureOnboard,
					{
						onSuccess: () => onSaveSuccess(saveStatus, onSuccess),
						onError: onSaveError,
					},
					onboard.campaignId,
					onboard.campaignMemberId,
					{ ...onboard, ...modifications }
				)
			);
		},
		[onboard]
	);

	// triggered when user makes changes
	useEffect(() => {
		if (isInitialRender.current) {
			isInitialRender.current = false;
		} else {
			setIsSaved(false);
		}
	}, [onboard]);

	const onCloseWizard = useCallback(() => {
		try {
			onSave(true);
		} finally {
			history.push('/');
		}
	}, [onSave]);

	const startWizardSteps = useCallback(() => {
		onSave(false, { step: 1 });
	}, [onSave]);

	const onSubmitSuccess = useCallback(() => {
		trackEvent('nurture-onboard-success');
		history.push(`/campaign/${onboard.campaignId}?tab=nurturelist`);
	}, [onboard.campaignId]);

	const submitOnboarding = useCallback(() => {
		setIsSubmitting(true);
		onSave(true, OnboardSubmitObject, onSubmitSuccess);
	}, [onSave, onSubmitSuccess]);

	const connectionsLabel = useMemo(
		() => numConnectionsLabel(onboard.linkedInNurtureListType, numConnections),
		[onboard.linkedInNurtureListType, numConnections]
	);

	const selectedMessagingTemplates = useMemo(
		() => [onboard.firstMessageExampleKey, onboard.secondMessageExampleKey],
		[onboard.firstMessageExampleKey, onboard.secondMessageExampleKey]
	);

	const navStepDescriptionNurtureList = useMemo(() => {
		const option = NurtureListWizardNavText[onboard.linkedInNurtureListType];
		return `${option} ${connectionsLabel}`;
	}, [onboard.linkedInNurtureListType, numConnections]);

	const navStepDescriptionMessaging = useMemo(() => {
		if (isMessagesValid(onboard.messages)) {
			return `${onboard.messages.length} Automated Message${getPluralizedEnding(
				onboard.messages
			)}`;
		} else return '';
	}, [onboard.messages]);

	const updateOnboardByKey = useCallback(
		(onboardKey: string, value: any) => {
			updateOnboard({ [onboardKey]: value });
		},
		[updateOnboard]
	);

	const resetMessageTemplates = useCallback(() => {
		updateOnboard({
			firstMessageExampleKey: '',
			secondMessageExampleKey: '',
		});
	}, [updateOnboard]);

	return onboard.step === WizardStepNode.NURTURE_TITLE ? (
		<NurtureWelcome
			activeMember={activeMember}
			onStartClicked={startWizardSteps}
			onCloseWizard={onCloseWizard}
		/>
	) : (
		<FullPageWizard farthestNode={onboard.step} isSaved={isSaved} onSave={onSave}>
			<FullPageWizard.Header>
				<DarkHeaderWithSupportLinks
					activeMember={activeMember}
					showSaved
					onCloseClicked={onCloseWizard}
				/>
			</FullPageWizard.Header>

			<FullPageWizard.Step
				id={WizardStepNode.NURTURE_TITLE}
				title={NurtureStepTitles[WizardStepNode.NURTURE_TITLE]}
				isDisabled
			/>

			<FullPageWizard.Step
				id={WizardStepNode.NURTURE_LIST}
				title={NurtureStepTitles[WizardStepNode.NURTURE_LIST]}
				description={navStepDescriptionNurtureList}
			>
				<FullPageWizard.Step.MainContent
					title={WizardStepHeaderText[WizardStepNode.NURTURE_LIST].title}
					description={WizardStepHeaderText[WizardStepNode.NURTURE_LIST].description}
				>
					<NurtureList
						selectedOption={onboard.linkedInNurtureListType}
						numConnections={numConnections}
						selectedTags={onboard.tags}
						availableTags={availableTags}
						onOptionSelected={(option) =>
							updateOnboard({ linkedInNurtureListType: option })
						}
						onSaveTagList={(tags) => updateOnboard({ tags })}
					/>
				</FullPageWizard.Step.MainContent>
				<FullPageWizard.Step.Info>
					<InfoSection
						title={WizardInfoSectionText[WizardStepNode.NURTURE_LIST].title}
						description={WizardInfoSectionText[WizardStepNode.NURTURE_LIST].description}
						url={WizardInfoSectionText[WizardStepNode.NURTURE_LIST].url}
					/>
				</FullPageWizard.Step.Info>
				<FullPageWizard.Step.Footer>
					<WizardStepNavButtonFooter isBackDisabled />
				</FullPageWizard.Step.Footer>
			</FullPageWizard.Step>

			<FullPageWizard.Step
				id={WizardStepNode.NURTURE_MESSAGING}
				title={NurtureStepTitles[WizardStepNode.NURTURE_MESSAGING]}
				description={navStepDescriptionMessaging}
			>
				<FullPageWizard.Step.MainContent
					title={WizardStepHeaderText[WizardStepNode.NURTURE_MESSAGING].title}
					description={WizardStepHeaderText[WizardStepNode.NURTURE_MESSAGING].description}
				>
					<NurtureMessaging
						messages={onboard.messages}
						selectedStrategy={onboard.messagingStrategy}
						selectedTemplates={selectedMessagingTemplates}
						onUpdate={(messages) => updateOnboard({ messages })}
						onStrategyUpdate={(strategy) =>
							updateOnboard({ messagingStrategy: strategy })
						}
						onSelectedTemplateUpdate={updateOnboardByKey}
						resetMessageTemplates={resetMessageTemplates}
					/>
				</FullPageWizard.Step.MainContent>
				<FullPageWizard.Step.Info>
					<InfoSection
						title={WizardInfoSectionText[WizardStepNode.NURTURE_MESSAGING].title}
						description={
							WizardInfoSectionText[WizardStepNode.NURTURE_MESSAGING].description
						}
						url={WizardInfoSectionText[WizardStepNode.NURTURE_MESSAGING].url}
					/>
				</FullPageWizard.Step.Info>
				<FullPageWizard.Step.Footer>
					<WizardStepNavButtonFooter
						isNextDisabled={!isMessagesValid(onboard.messages)}
					/>
				</FullPageWizard.Step.Footer>
			</FullPageWizard.Step>

			<FullPageWizard.Step
				id={WizardStepNode.NURTURE_REVIEW}
				title={NurtureStepTitles[WizardStepNode.NURTURE_REVIEW]}
				isAlwaysSaved
			>
				<FullPageWizard.Step.MainContent
					title={WizardStepHeaderText[WizardStepNode.NURTURE_REVIEW].title}
					description={WizardStepHeaderText[WizardStepNode.NURTURE_REVIEW].description}
				>
					<NurtureReview
						nurtureListOption={
							{
								optionType: onboard.linkedInNurtureListType,
								connectionsLabel,
								tags: onboard.tags,
							} as NurtureListOption
						}
						messages={onboard.messages.map((message) => message.text)}
					/>
				</FullPageWizard.Step.MainContent>
				<FullPageWizard.Step.Info />
				<FullPageWizard.Step.Footer>
					<WizardStepNavButtonFooter
						nextLabel={isSubmitting ? 'Saving...' : 'Finish'}
						onNextClick={submitOnboarding}
						isNextLoading={isSubmitting}
					/>
				</FullPageWizard.Step.Footer>
			</FullPageWizard.Step>
		</FullPageWizard>
	);
};

const withNumConnections =
	<T extends INurtureOnboardProps>(Component: React.FC<T>) =>
	(props: T) => {
		const { onboard } = props;

		const [numOldConnections, setNumOldConnections] = useState<number>();
		const [numNeverRepliedConnections, setNumNeverRepliedConnections] = useState<number>();
		const [numTagConnections, setNumTagConnections] = useState<number>();

		useEffectAsync(async () => {
			const result = await NurtureOnboardManager.getNumOldConnections();
			setNumOldConnections(result);
		}, []);

		useEffectAsync(async () => {
			const result = await NurtureOnboardManager.getNumNeverRepliedConnections();
			setNumNeverRepliedConnections(result);
		}, []);

		useEffectAsync(async () => {
			const result = await NurtureOnboardManager.getNumTagConnections(onboard.tags);
			setNumTagConnections(result);
		}, [onboard.tags]);

		const numConnections: NurtureListNumConnections = useMemo(
			() => ({
				[NurtureListOptionType.Old]: numOldConnections,
				[NurtureListOptionType.NeverReplied]: numNeverRepliedConnections,
				[NurtureListOptionType.Tag]: numTagConnections,
			}),
			[numOldConnections, numNeverRepliedConnections, numTagConnections]
		);

		return <Component {...props} numConnections={numConnections} />;
	};

interface OnboardRouterParams {
	campaignId: string;
}

const withOnboardObject =
	<T extends Omit<INurtureOnboardProps, 'numConnections'>>(Component: React.FC<T>) =>
	(props: T) => {
		// Added agency check as onboard wizards contain CopilotAI-specific copy
		if (Config.isAgency) return UtilityFunctions.redirectToMain();
		const { campaignId }: OnboardRouterParams = useParams();
		const [, fetchIsOnboardComplete] = useFetch(NurtureOnboardManager.getIsOnboardingComplete);

		const dispatch = useDispatch();
		const history = useHistory();

		const savedOnboard = useSelector(getNurtureOnboard).data;
		const [onboard, setOnboard] = useState<NurtureOnboardModel>();

		const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
		const activeCampaignMember = useSelector(
			CampaignMemberSelectors.getByCampaignAndOrgMember(activeMember?.id ?? '', campaignId)
		);

		const orgTags = useSelector(getAllOrgTags);
		const availableTags = React.useMemo(() => orgTags.data.map((t) => t.name), [orgTags]);

		useEffectAsync(async () => {
			const isOnboarded = activeCampaignMember?.id
				? await fetchIsOnboardComplete(activeCampaignMember.id)
				: false;
			if (savedOnboard?.step === WizardStepNode.NURTURE_COMPLETE || isOnboarded) {
				history.push(`/campaign/${campaignId}`);
			}
			setOnboard(savedOnboard);
		}, [activeCampaignMember?.id, fetchIsOnboardComplete, savedOnboard, campaignId]);

		// when updating while saving, use the modifications param provided in the onSave function
		const updateOnboard = useCallback((updates: Partial<NurtureOnboardModel>) => {
			setOnboard((current) => (current ? { ...current, ...updates } : current));
		}, []);

		useEffect(() => {
			dispatch(fetchNurtureOnboard(NurtureOnboardManager.getNurtureOnboard, {}, campaignId));
		}, [campaignId]);

		return !onboard || !activeMember || !availableTags ? (
			<Spin size="large">
				{' '}
				<Skeleton paragraph={{ rows: 15 }} />{' '}
			</Spin>
		) : (
			<Component
				{...props}
				onboard={onboard}
				activeMember={activeMember}
				availableTags={availableTags}
				updateOnboard={updateOnboard}
			/>
		);
	};

/**
 * Full page nurture onboard wizard for new nurture campaigns
 */
export default withAITracking(
	appInsights.reactPlugin,
	withOnboardObject(withNumConnections(NurtureOnboard)),
	'Nurture Onboard'
);
