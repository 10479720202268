import { MessageTypeEnum } from '@copilot/common/pages/onboard/wizard/messaging/const';
import {
	LinkedInSentMessageError,
	LinkedInThreadType,
	NodeConditionTypeEnum,
	NodeTimerPeriodEnum,
	NurtureListOptionType,
	NurtureMessageTypeEnum,
	SearchCriteriaType,
	TagLevel,
} from '@copilot/common/store/models/const/enum';
import {
	CampaignStatusEnum,
	ConnectionsFilterRequestModel,
	OnboardCriteriaRequestModel,
	OnboardMessage,
	InboxDisplayType,
} from '../requests/models';
import { ScheduleHierarchyType } from '@copilot/common/pages/settings/schedule/constant';
import { InsightAnalysis } from '../managers/personalizedInsights';
import { CrmAccountDto, CrmSsoConnectionDto } from './models/crmAccount';
import { InMailStatus } from '../../../web/common/constant/inMailConstant';
import { ReminderStatus } from '@copilot/common/pages/inbox/data/types';

export interface EmptyResponse {}

export interface PaginatedResponse<R> {
	numPages: number;
	pageIndex: number;
	pageSize: number;
	results: R[];
	startingIndex: number;
	totalCount: number;
}

export type LoadMorePaginatedResponse<R> = {
	offset: number;
	pageSize: number;
	results: R[];
	hasMore: boolean;
};

export enum LinkedInSearchType {
	Unknown = -1,
	Url = 0,
	Criteria,
}

export enum CampaignApprovalStatus {
	Rejected = -3,
	Disconnected = -2,
	Incomplete = -1,
	Waiting,
	Approved,
}
export enum CampaignType {
	Prospecting = 'LinkedInProspecting',
	Nurture = 'LinkedInNurture',
}

/**
 * Possible errors when launching a campaign
 */
export enum CampaignLaunchErrors {
	NoSalesNavUrl = 'NO_SALES_NAV_URL',
	NoCampaignSequence = 'NO_CAMPAIGN_SEQUENCE',
	NoServiceSchedule = 'NO_SERVICE_SCHEDULE',
	NoEnabledMembers = 'NO_ENABLED_MEMBERS',
}

export interface CampaignResponse {
	id: string;
	organizationId: string;
	name: string;
	members: CampaignMemberResponse[];
	startDate: Date;
	endDate: Date;
	status: CampaignStatusEnum;
	primaryColour: string;
	tagStats: string[];
	actionStats: string[];
	approvalStatus: CampaignApprovalStatus;
	type: CampaignType;
}

export interface DaySchedule {
	start: number;
	end: number;
	enable: boolean;
}

export interface ServiceSchedule {
	id: string;
	ownerId: string;
	hierarchyType: ScheduleHierarchyType;
	timezoneCode: string;
	synchronization: boolean;
	weeklySchedule: DaySchedule[];
}

export interface SearchCriteria extends OnboardCriteriaRequestModel {
	id: string;
	name: string;
	industries: string[];
	ownerId: string;
	templateId: string;
}

export interface CampaignOnboardDetails {
	searchCriteria?: Partial<SearchCriteria>;
	searchType: LinkedInSearchType;
	searchUrl: string;
	calendlyEventId: string;
	meetingDate: string;
	status: number;
	step: number;
	messages: OnboardMessage[];
}

export interface CampaignMemberResponse {
	id: string;
	orgMemberId: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	campaignRoles: string[];
	status: number;
}

/**
 * Response payload from campaign pause routes
 */
export interface CampaignPauseResponseDTO {
	unpauseDate?: string;
}

export interface SearchCriteria {
	experience: string[];
	id: string;
	locations: string[];
	name: string;
	ownerId: string;
	templateId: string;
	titles: string[];
	type: SearchCriteriaType;
	exclusions: string[];
	extraTitles: string[];
	headCount: string[];
	industries: string[];
	radius: string;
	recentJobChange: boolean;
	zip: string[];
}

export interface ContactResponse {
	id: string;
	organizationId: string;
	position: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	company: string;
	addressLine: string;
	city: string;
	country: string;
	state: string;
	zipCode: string;
	connectedMemberIds: string[];
	campaignIds: string[];
	notes: {
		id: string;
		orgMemberId: string;
		contactId: string;
		connectionId: string;
		campaignMemberId: string;
		timestamp: Date;
		message: string;
		metaData: { [k: string]: string };
	}[];
	reminders: {
		id: string;
		orgMemberId: string;
		contactId: string;
		connectionId: string;
		campaignMemberId: string;
		timestamp: Date;
		entityType: number;
		message: string;
		targetNodeId: string;
		triggerDateTime: Date;
		metaData: { [k: string]: string };
	}[];
	tags: { id: string; name: string; tagId: string; contactId: string; connectionId: string }[];
	dateCreated: Date;
	lastThreadActivity: Date;
}

export interface IHistory<T> {
	data: T;
	dates: string[];
}

export interface ContactTagResponse {
	connectionId: string;
	contactId: string;
	id: string;
	name: string;
	tagId: string;
}

/**
 * Meeting details for a connection
 */
export type MeetingDetails = Readonly<{
	/**
	 * Whether a meeting has been booked
	 */
	booked: boolean;
}>;

export interface ConnectionResponse {
	id: string;
	contactId: string;
	name: string;
	campaignName: string;
	campaignType: string;
	campaignMemberId: string;
	orgMemberId: string;
	campaignId: string;
	orgMemberName: string;
	orgMemberProfileId: string;
	profileId: string;
	lastThreadActivity: string;
	dateCreated: string;
	tags: ContactTagResponse[];
	position: string;
	company: string;
	email: string;
	phoneNumber: string;
	location: string;
	history: [{ campaignName: string; dates: string }];
	/**
	 * Meeting details for the connection
	 */
	meeting: MeetingDetails;
}

export interface ContactConnectionsResponse {
	name: string;
	contactId: string;
	position: string;
	company: string;
	email: string;
	phoneNumber: string;
	location: string;
	connections: ConnectionResponse[];
}

export interface LoginResponseToken {
	email: string;
	jti: string;
	firstName: string;
	lastName: string;
	oid: string;
	orole: string;
	omid: string;
	otype: string;
	'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string;
	exp: number;
	iss: string;
	aud: string;
	userHash: string;
	tid: string;
	iDisplayName: string;
	iid: string;
}
export interface LoginResponse {
	access_token: string;
	expires_in: number;
	refresh_token: string;
}

export enum PushMethods {
	None = 0,
	Email = 2,
}

export enum NotificationOptions {
	INSTANT = 'EMILY_INSTANT',
	WEEKLY = 'EMILY_WEEKLY',
	MONTHLY = 'EMILY_MONTHLY',
	SENDRECEIPT = 'EMILY_SENDRECIEPT',
	ACCOUNTALERTS = 'EMILY_ACCOUNTALERTS',
	TEAMS_WEEKLY = 'EMILY_TEAMS_WEEKLY',
	TEAMS_MONTHLY = 'EMILY_TEAMS_MONTHLY',
	TEAMS_SUMMARYALERTS = 'EMILY_TEAMS_SUMMARYALERTS',
}

export interface NotificationTemplate {
	availablePushMethods: PushMethods;
	description: string;
	id: string;
	isActive: boolean;
	metaData: any;
	notificationCode: NotificationOptions;
	subscriptions: { [x: string]: any };
}

export type NotificationSubscriptionResponse = Array<NotificationTemplate>;

export interface OrganizationResponse {
	actionStats: string[];
	id: string;
	logoUrl: string;
	name: string;
	orgType: number;
	tagStats: string[];
	members: string[];
	/** MaxSeats === null when EnhancedBillingFeature turned on. */
	maxSeats?: number;
	multipleInvite: MultipleInvite;
	/**
	 * Whether the organization is in free trial mode
	 */
	isFreeTrial: boolean;
}

export interface OrganizationAdminDetailsResponse {
	organizationId: string;
	/** MaxSeats === null when EnhancedBillingFeature turned on. */
	maxSeats?: number;
	/** Max smart reply credits */
	maxSmartReplyCredits: number;
	monthlyInvites: number;
	monthlyMessages: number;
	dailyInvites: number;
	dailyMessages: number;
	dailyOpenInMailLimit: number;
	orgType: number;
	customerSuccessRepId: string;
	customerSuccessStatus: string;
	instanceId: string;
}

export interface MemberLinkedInProfileResponse {
	id: string;
	urn: string;
	name: string;
	title: string;
	company: string;
	alias: string;
	isOpenProfile: boolean;
}

export interface LinkedinOrganizationMemberResponse {
	orgMemberId: string;
	linkedInProfile: MemberLinkedInProfileResponse | null;
	isLoggedIn: boolean;
	dailyInvitesAllocated: number;
	dailyMessagesAllocated: number;
	dailyOpenInMailAllocated: number;
	multipleInvite: MultipleInvite;
}

export interface LinkedInContactResponse {
	contactId: string;
	linkedInProfile: MemberLinkedInProfileResponse;
}

export enum LinkedInMessageType {
	Unknown = -1,
	Sent,
	Received,
	System,
	All,
}

export interface OrganizationMemberSettingsResponse {
	inboxType: InboxDisplayType;
	hideLiSearchVideo: boolean;
	isCampaignAccordionExpanded: boolean;
	isAdvanced: boolean;
}

export interface OrganizationMemberResponse {
	feSettings?: OrganizationMemberSettingsResponse;
	id: string;
	userId: string;
	organizationId: string;
	organizationMemberId: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	orgRoles: string[];
	userRoles: string[];
	vpnPort: number;
	startDate: string;
	stopDate: string;
	stripeCustomerId: string;
	cc: string[];
	bcc: string[];
	alternateEmail: string;
	isActive: boolean;
	lastActivationDate?: string;
	nextDeactivationDate: string;
	hasInvalidSalesNavSubscription: boolean;
	isLiSessionInRetryState: boolean;
	isOnboarded: boolean;
}

export interface RegisterOrganizationMembersError {
	email: string;
	message: string;
}

export interface RegisterOrganizationMembersResponse {
	successfulRegistrations: OrganizationMemberResponse[];
	errors: RegisterOrganizationMembersError[];
}

export interface MultipleInvite {
	multipleInvite: boolean;
	period: NodeTimerPeriodEnum;
	time: number;
}

interface TaskMessageResponse {
	code: string;
	payload: string;
	timestamp: Date;
}

export enum LinkedInMessageStatus {
	Canceled = -3,
	Failed = -2,
	Unknown = -1,
	Pending,
	Success,
	InProgress,
}

export enum TaskStatus {
	UnexpectedStop = 'UnexpectedStop',
	Aborted = 'Aborted',
	Failed = 'Failed',
	Created = 'Created',
	InProgress = 'InProgress',
	Paused = 'Paused',
	Complete = 'Complete',
}

export interface TaskResponse {
	taskId: string;
	taskCode: string;
	status: TaskStatus;
	lastMessage: TaskMessageResponse;
	payload: string;
}

export interface InboxMessageDetailResponse {
	message: string;
	senderId: string;
	receiverId: string;
	timestamp: string;
	threadId: string;
}

export type InboxMessageTagModel = {
	id: string;
	name: string;
	contactId: string;
	connectionId: string;
	tagId: string;
};

export interface InboxResponse {
	id: string;
	campaignId: string;
	campaignName: string;
	campaignType: string;
	orgMemberId: string;
	orgMemberName: string;
	campaignMemberId: string;
	contactId: string;
	contactName: string;
	connectionId: string;
	currentNode: SequenceNodeResponse;
	nextNodes: SequenceNodeResponse[];
	lastReceived: InboxMessageDetailResponse;
	lastSent: InboxMessageDetailResponse | undefined;
	lastMessage: InboxMessageDetailResponse;
	primaryCampaignColour: string;
	threadId: string;
	linkedInThreadId: string;
	aiResult: { [s: string]: { confidence: number; value: any } };
	polarity: string;
	status: any;
	inProgress: boolean;
	company: string;
	position: string;
	phoneNumber: string;
	email: string;
	isRead: boolean;
	isConversationRestricted: boolean;
	isLoggedIn: boolean;
	isLiSessionInRetryState: boolean;
	isSnoozed: boolean | undefined;
	tags: InboxMessageTagModel[];
	messageCount: number;
	linkedInProfileId: string;
	inMail?: {
		status: InMailStatus;
	};
	reminder?: {
		hasReminder: boolean;
		reminderTimestamp: string;
		reminderType: ReminderStatus;
	};
}

export interface TagDTO {
	name: string;
	description: string;
	id: string;
	level: TagLevel;
}

export interface TagResponse {
	name: string;
	description: string;
	id: string;
	isSystem: boolean;
}

export type StatsResponse = Array<Stats>;
export interface Stats {
	id: string;
	period: number;
	tagStats: { [k: string]: number };
	actionStats: { [k: string]: number };
	ownerId: string;
	timestamp: string;
	bookmark: string;
}

export interface StatsOptionsListResponse {
	actionStats: string[];
	tagStats: string[];
}

enum MessageSendError {
	Unknown = -1,
	NoError,
	General,
	ThreadRestricted,
	StateChanged,
	RecipientMismatch,
	LoadFailed,
	Disconnected,
	AccountClosed,
	LoggedOut,
}

export interface OutboxResponse {
	threadId: string;
	message: string;
	timestamp: string;
	orgMemberId: string;
	contactId: string;
	dateCreated: string;
	status: any;
	error: MessageSendError;
	firstName: string;
	lastName: string;
	company: string;
	organizationId: string;
	id: string;
	campaignName: string;
	campaignId: string;
}

export interface SentResponse {
	threadId: string;
	message: string;
	timestamp: string;
	lastSent: string;
	orgMemberId: string;
	contactId: string;
	dateCreated: string;
	status: any;
	error: MessageSendError;
	firstName: string;
	lastName: string;
	company: string;
	organizationId: string;
	id: string;
	campaignName: string;
	campaignId: string;
	isContactDeleted: boolean;
}

/**
 * Define properties in scheduled message response
 */
export interface ScheduledResponse {
	threadId: string;
	/** message content */
	message: string;
	timestamp: string;
	orgMemberId: string;
	contactId: string;
	dateCreated: string;
	status: LinkedInMessageStatus;
	error: LinkedInSentMessageError;
	firstName: string;
	lastName: string;
	company: string;
	organizationId: string;
	id: string;
	/** id of message creator */
	addedBy: string;
}

export interface IScheduledEntity {
	id: string;
	orgMemberId: string;
	contactId: string;
	connectionId: string;
	campaignMemberId: string;
	timestamp: Date;
	scheduledEntityType: string;
	message: string;
	targetNodeId: string;
	triggerDateTime: Date;
}

export interface MessageResponse {
	data: string;
	isSelf: boolean;
	timestamp?: Date;
	pending?: boolean;
	status: LinkedInMessageStatus;
	triggerDate?: Date;
	inMailStatus?: InMailStatus;
	subject?: string;
}

export interface ConversationResponse {
	id: string;
	sourceId: string;
	sourceName: string;
	targetId: string;
	targetName: string;
	threadId: string;
	threadType: LinkedInThreadType;
	receivedMessages: MessageResponse[];
	sentMessages: MessageResponse[];
	isConversationRestricted: boolean;
	isLoggedIn: boolean;
	isLiSessionInRetryState: boolean;
	isSnoozed: boolean;
	snoozedDateTime?: string;
}

export type ConversationWithTargetProfileResponse = {
	thread: ConversationResponse;
	targetProfile: MemberLinkedInProfileResponse;
};

export interface MessageTemplateResponse {
	id: string;
	organizationId: string;
	orgMemberId: string;
	campaignIds: string[];
	name: string;
	message: string;
}

export interface NotificationResponse {
	Pending: number;
	Error: number;
	Scheduled: number;
	Sent: number;
}

export interface ConnectionResponse {
	id: string;
	campaignMemberId: string;
	contactId: string;
	campaignId: string;
	inviteUrl: string;
	connectionStatus: string;
	currentStep: number;
	currentStepTrigger: string | null;
	firstName: string;
	lastName: string;
	email: string;
	lastThreadActivity: string;
	dateCreated: string;
	tags: ContactTagResponse[];
	location: string;
	position: string;
	company: string;
	matchScore?: number | null;
	inMailStatus?: string | null;
}

export interface LinkedInCampaignMemberResponse {
	campaignMemberId: string;
	linkedInProfile: MemberLinkedInProfileResponse;
	searchUrl: string;
	lastSearchUrlUpdate: string;
	dailyInvites: number;
	dailyMessages: number;
	dailyOpenInMailLimit: number;
	openInMailEnabled: boolean;
	multipleInvite: MultipleInvite;
}

export interface SequenceNodeTriggerCondition {
	name: string;
	conditionType: NodeConditionTypeEnum;
	parameters: string[][];
}

export interface SequenceNodeAction {
	id: number;
	name: string;
	actionType: string;
	parameters: string[];
	priority: number;
}

export interface SequenceNodeTrigger {
	name: string;
	conditions: SequenceNodeTriggerCondition[];
}

export interface SequenceNodeResponse {
	id: string;
	name: string;
	triggers: SequenceNodeTrigger[];
	actions: SequenceNodeAction[];
	nextNodeIds: string[];
}

export interface LinkedInOrganizationSettingsResponse {
	dailyInvitesPerUser: number;
	dailyMessagesPerUser: number;
	dailyOpenInMailPerUser: number;
	monthlyInvitePerUser: number;
	monthlyMessagePerUser: number;
}

export interface AdminClientResponse {
	id: string;
	name: string;
	userId: string;
	organizationId: string;
	organizationMemberId: string;
	campaigns: { [key: string]: ClientCampaignResponse };
	isCampaignRunning: false;
	daysLastSeen: number;
	monthlyInvite: number;
	monthlyMessage: number;
	billingEndDate: string;
	serviceStartDate: string;
	serviceStopDate: string;
	isSubscriptionActive: boolean;
	inviteProgress: number;
	customerSuccessName: string;
	customerSuccessId: string;
	status: string;
	linkedInError: number;
	isEnabled: boolean;
	isPaused: boolean;
	stats: any;
	activationsRequired: number;
	approvedCampaigns: number;
	totalActiveCampaigns: number;
	totalRunningCampaigns: number;
	totalRunningNurtureCampaigns: number;
	isAdvanced: boolean;
}

export interface AdminTeamClientResponse {
	id: string;
	name: string;
	organizationId: string;
	ownerId: string;
	instanceId: string;
	maxInvitesCount: number;
	maxMessagesCount: number;
	/** MaxSeats === null when EnhancedBillingFeature turned on. */
	maxSeats?: number;
	billingEndDate?: string;
	customerSuccessName: string;
	customerSuccessUserId: string;
	totalActiveCampaigns: number;
	totalRunningNurtureCampaigns: number;
	members: ITeamClientMemberResponse[];
	teamsCustomerId: string;
	status: string;
	seatsAllocated: number;
	activeMemberCount: number;
	tenantId: string;
}

export interface AdminClientCreateModel {
	instanceId: string | null;
	firmName: string;
	monthlyInvites: number;
	monthlyMessages: number;
	vpnPort: number;
	stripeCustomerId: string;
	email: string;
	password: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	isIndividualUser: boolean;
	orgRoles: string[];
	tenantId: string | null;
}

export interface CSMemberResponse {
	firstName: 'string';
	lastName: 'string';
	userId: 'string';
	company: 'string';
	email: 'string';
}

export interface ClientPersonalDataModel {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	company: string;
	email: string;
}
export interface AdminMemberResponse {
	id: string;
	userId: string;
	calendlyUrl: string;
}

export interface ClientCampaignResponse {
	campaignMemberId: string;
	id: string;
	isCampaignRunning: boolean;
	isPaused: boolean;
	linkedInError: number;
	unpauseDate: Date;
	campaignType: CampaignType;
	approvalStatus: CampaignApprovalStatus;
	status: CampaignStatusEnum;
	name: string;
}

export interface ITeamClientMemberResponse {
	name: string;
	orgMemberId: string;
	email: string;
	isLoggedOut: boolean;
	vpnPort: number;
	isEnabled: boolean;
	isActive: boolean;
	orgRole: string;
	linkedInError: number;
	withdrawExternalInvites: boolean;
	weeksBeforeWithdrawal: number;
}

export interface BillingInfoResponse {
	amountDue: number;
	currency: string;
	amountDueFormatted: string;
	discountDescription: string;
	billingCycleEndDate: string;
	productDescription: string;
}

export interface FilterTemplateResponse {
	id: string;
	ownerId: string;
	name: string;
	filters: Partial<ConnectionsFilterRequestModel>;
}

export interface NurtureOnboardResponse {
	id: string;
	campaignId: string;
	campaignMemberId: string;
	messages: OnboardMessage[] | null;
	tags: string[] | null;
	linkedInNurtureListType: NurtureListOptionType;
	messagingStrategy: NurtureMessageTypeEnum;
	firstMessageExampleKey: string | null;
	secondMessageExampleKey: string | null;
	step: number;
}

export interface NewUserOnboardResponse extends CampaignOnboardDetails {
	id: string;
	campaignId: string;
	messagingStrategy: MessageTypeEnum;
	firstMessageExampleKey: string | null;
	secondMessageExampleKey: string | null;
	version: number;
	groupId: string;
	serviceSchedule?: ServiceSchedule;
	isFirstLogin: boolean;
}

export enum Features {
	NurtureCampaign = 'CopilotAI.Campaign.NurtureFeature',
	EventTracking = 'CopilotAI.Tracking.EventTrackingFeature',
	GroupingClickAndSend = 'CopilotAI.Campaign.GroupingClickAndSendFeature',
	NewOnboardFeature = 'CopilotAI.Organization.NewOnboardFeature',
	TeamsAttentionRequiredFeature = 'CopilotAI.WebAPI.Features.TeamsAttentionRequiredFeature',
	SearchListEnhancementsFeature = 'CopilotAI.WebAPI.Features.SearchListEnhancementsFeature',
	VideoValidationFeature = 'CopilotAI.WebAPI.Features.VideoValidationFeature',
	BillingChangeSubscriptionFeature = 'CopilotAI.WebAPI.Features.BillingChangeSubscriptionFeature',
	OnboardSideBarRemovalFeature = 'CopilotAI.WebAPI.Features.OnboardSideNavRemovalFeature',
	OnboardMessageImprovementFeature = 'CopilotAI.WebAPI.Features.OnboardMessageImprovementFeature',
	OnboardSearchCriteriaRemovalFeature = 'CopilotAI.WebAPI.Features.OnboardSearchCriteriaRemovalFeature',
	IndividualAdvancedToggleFeature = 'CopilotAI.WebAPI.Features.IndividualAdvancedToggleFeature',
	PersonalityInsightsFeature = 'CopilotAI.WebAPI.Features.PersonalityInsightsFeature',
	NewAllCampaignFeature = 'CopilotAI.WebAPI.Features.NewAllCampaignFeature',
	CampaignDashboardV2Feature = 'CopilotAI.WebAPI.Features.CampaignDashboardV2Feature',
	OrgHubV2Feature = 'CopilotAI.WebAPI.Features.OrgHubV2Feature',
	ReplyPredictionFeature = 'CopilotAI.WebAPI.Features.ReplyPredictionFeature',
	ChatGPTFeature = 'CopilotAI.WebAPI.Features.ChatGPTFeature',
	ChatCreditGatingFeature = 'CopilotAI.WebAPI.Features.ChatCreditGatingFeature',
	SmartReplySaveResponseFeature = 'CopilotAI.WebAPI.Features.SmartReplySaveResponseFeature',
	MeetingBookedReportingAndHistoryFeature = 'CopilotAI.WebAPI.Features.MeetingBookedReportingAndHistoryFeature',
	CustomerLayerTenantFeature = 'CopilotAI.WebAPI.Features.CustomerLayerTenantFeature',
	AIChatServiceFeature = 'CopilotAI.WebAPI.Features.AIChatServiceFeature',
	TextEditorCompoundComponentFeature = 'CopilotAI.WebAPI.Features.TextEditorCompoundComponentFeature',
	SmartReplyEditFeature = 'CopilotAI.WebAPI.Features.SmartReplyEditFeature',
	TenantDashboardFeature = 'CopilotAI.WebAPI.Features.TenantDashboardFeature',
	CleverlyOnTeamsFeature = 'CopilotAI.WebAPI.Features.CleverlyOnTeamsFeature',
	SwitchTenantComponentFeature = 'CopilotAI.WebAPI.Features.SwitchTenantComponentFeature',
	ReminderTabFeature = 'CopilotAI.WebAPI.Features.ReminderTabFeature',
	AccountDashboardFeature = 'CopilotAI.WebAPI.Features.AccountDashboardFeature',
	AccountDataFeature = 'CopilotAI.WebAPI.Features.AccountDataFeature',
	CRMClientIntegrationFeature = 'CopilotAI.WebAPI.Features.CRMClientIntegrationFeature',
	TeamInboxUpsellFeature = 'CopilotAI.WebAPI.Features.TeamInboxUpsellFeature',
	DoNotProspectFeature = 'CopilotAI.Campaign.DoNotProspectFeature',
	DoNotProspectRefactorFeature = 'CopilotAI.Campaign.DoNotProspectRefactorFeature',
	StopAutomationRefactorFeature = 'CopilotAI.WebAPI.Features.StopAutomationRefactorFeature',
	LinkedInLogoutIncidentFeature = 'CopilotAI.WebAPI.Features.LinkedInLogoutIncidentFeature',
	ManualSendMessageFeature = 'CopilotAI.WebAPI.Features.ManualSendMessageFeature',
	RunSearchListFeature = 'CopilotAI.WebAPI.Features.RunSearchListFeature',
	UseHostedAuthFeature = 'CopilotAI.WebAPI.Features.UnipileHostedAuthFeature',
}

export const TermsOfUse = {
	AIFeature: 'AIFeature',
} as const;

export interface ConnectionMigrationResponse {
	migratableConnections: ConnectionResponse[];
	nonMigratableConnections: ConnectionResponse[];
}

export interface DefaultMessageTemplates {
	strategy: number;
	groupId: string;
	messageTemplates: string[];
	version: number;
}

export type DefaultMessageTemplatesResponse = DefaultMessageTemplates[];

export interface CreditsDescriptor {
	available: number;
	consumed: number;
}

export interface PersonalityInsightsResponse {
	analysisStatus: string;
	analysis: InsightAnalysis;
	credits: CreditsDescriptor;
}

export type CrmAccountResponseDto = {
	success: boolean;
	data: CrmAccountDto;
};

export type CrmAccountDeleteResponseDto = {
	success: boolean;
	data: CrmAccountDto[] | null;
};

export type CrmSsoConnectionResponseDto = {
	success: boolean;
	data: CrmSsoConnectionDto | CrmSsoConnectionDto[] | null;
};
