import React, { useMemo, useContext } from 'react';
import WizardWelcome from '@copilot/common/components/wizard/welcome';
import { DarkHeaderWithSupportLinks } from '@copilot/common/components/menus/header/Logo';
import {
	WizardStepNode,
	WizardStepHeaderText,
} from '@copilot/common/pages/wizard/nurtureOnboard/const';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import { ThemeContext } from 'styled-components';
import { TagTwoTone, CheckCircleTwoTone, NotificationTwoTone } from '@ant-design/icons';
import { Config } from '@copilot/common/config';

interface INurtureWelcomeProps {
	activeMember: IOrganizationMember;
	onStartClicked: () => void;
	onCloseWizard: () => void;
}

/**
 * Initial welcome page of the nurture onboard wizard.
 * @param {IOrganizationMember} activeMember current member
 * @param {function} onStartClicked called when the start button is clicked
 * @param {function} onCloseWizard called when we want to close the wizard
 */
const NurtureWelcome: React.FC<INurtureWelcomeProps> = (props) => {
	const { activeMember, onStartClicked, onCloseWizard } = props;
	const themeContext = useContext(ThemeContext);

	const iconCards = useMemo(() => {
		const iconColor = themeContext['@primary-color'];
		return [
			{
				text: 'Free of Charge',
				icon: <TagTwoTone twoToneColor={iconColor} />,
			},
			{
				text: 'Easy to set-up',
				icon: <CheckCircleTwoTone twoToneColor={iconColor} />,
			},
			{
				text: 'Re-engage your prospects',
				icon: <NotificationTwoTone twoToneColor={iconColor} />,
			},
		];
	}, [themeContext]);

	return (
		<WizardWelcome>
			<WizardWelcome.Header>
				<DarkHeaderWithSupportLinks
					activeMember={activeMember}
					showSaved={false}
					onCloseClicked={onCloseWizard}
				/>
			</WizardWelcome.Header>
			<WizardWelcome.Content
				title={WizardStepHeaderText[WizardStepNode.NURTURE_TITLE].title}
				description={WizardStepHeaderText[WizardStepNode.NURTURE_TITLE].description}
				learnMoreUrl={Config.nurtureOverviewURL}
				iconCardData={iconCards}
				buttonLabel="Get Started"
				linkLabel="Return to Dashboard"
				onStartClicked={onStartClicked}
				onLinkClicked={onCloseWizard}
			/>
		</WizardWelcome>
	);
};

export default NurtureWelcome;
