export const MenuKeys = {
	Root: '/',
	Inbox: '/inbox',
	Outbox: '/outbox',
	Sent: '/sent',
	Campaign: '/campaign',
	Connections: '/connections',
	Settings: '/settings',
	Referral: '/referral',
};
