import Store from '@copilot/common/store';

// import { createBrowserHistory } from 'history';
// import CommonStore from '@copilot/common/store';
// import { createEntitiesReducer } from '@copilot/common/store/reducers/entities';
// import { createReducer } from '@copilot/common/store/reducers';
// import { orm } from './orm';

// export const History = createBrowserHistory();
// const entitiesReducer = createEntitiesReducer(orm);
// const reducer = createReducer<typeof entitiesReducer>(History, {
// 	entities: entitiesReducer
// });
// const Store = new CommonStore(reducer, History);
// export default Store;

export default Store;
