import { Button, Row, Space, Tooltip } from 'antd';
import ReminderSelector from '@copilot/common/components/selector/reminder';
import { ReminderDateOptions } from '@copilot/common/utils/dateUtil';
import { SendOutlined, PlayCircleFilled } from '@ant-design/icons';
import { useState } from 'react';
import { Moment } from 'moment/moment';
import {
	TextEditorContextActions,
	useTextEditorContext,
} from '@copilot/common/components/forms/textEditor/context';
import { isEmptyString } from '@copilot/common/utils/common';
import isNil from 'lodash/isNil';

type FooterPropsType = {
	/**
	 * if the automation steps is hidden
	 */
	showAutomationSteps?: boolean;
	/**
	 * callback when the user sends a message
	 */
	onSubmit: (
		message: string,
		nodeId?: string,
		quickResponseId?: string,
		reminderDate?: Moment
	) => void;

	isReminderOn?: boolean;
	reminderDate?: Moment;
};

/**
 * [Presentational] Text Editor footer with automated steps and reminder
 * @param props
 * @constructor
 */
export function TextEditorAutomatedStepsAndReminderFooter({
	onSubmit,
	showAutomationSteps = false,
	isReminderOn: initIsReminderOn = false,
	reminderDate: initReminderDate,
}: FooterPropsType) {
	const { text, automatedStepId, quickResponseId, disabled, dispatchUpdate } =
		useTextEditorContext();
	const [reminderDate, setReminderDate] = useState<Moment | undefined>(initReminderDate);
	const [isReminderOn, setIsReminderOn] = useState<boolean>(initIsReminderOn);
	const [isSendButtonTooltipOn, setIsSendButtonTooltipOn] = useState<boolean>(false);
	const isSendDisabled = (isReminderOn && !reminderDate) || isEmptyString(text);
	const isInResumeAutomationMode = automatedStepId !== undefined;

	/**
	 * Callback to handle showing and hiding the submit button tooltip
	 * @param visible
	 */
	function onTooltipVisibleChange(visible: boolean) {
		setIsSendButtonTooltipOn(visible && isReminderOn && isNil(reminderDate));
	}

	/**
	 * Callback to handle date change for reminder
	 * @param date
	 */
	function onDateChangeReminder(date: Moment) {
		setReminderDate(date);
		setIsReminderOn(true);
	}

	/**
	 * Callback to handle submitting messages
	 */
	function handleSubmit() {
		onSubmit(
			text,
			automatedStepId,
			quickResponseId,
			isReminderOn && !isInResumeAutomationMode ? reminderDate : undefined
		);
		dispatchUpdate({ type: TextEditorContextActions.reset });
		setIsReminderOn(false);
		setReminderDate(undefined);
	}

	/**
	 * Toggle to enable or disable the reminder feature
	 */
	function onReminderCheckToggle() {
		setIsReminderOn((checked) => !checked);
	}

	function onCancelResumeAutomation() {
		dispatchUpdate({
			type: TextEditorContextActions.setAutomatedStep,
			payload: { text, automatedStepId: undefined },
		});
		setIsReminderOn(false);
		setReminderDate(undefined);
	}

	return (
		<Row justify={'space-between'} align={'middle'}>
			<Space
				style={
					!showAutomationSteps && !isInResumeAutomationMode
						? { justifyContent: 'space-between', width: '100%' }
						: { marginLeft: 'auto' }
				}
			>
				{isInResumeAutomationMode ? (
					<Button style={{ height: '40px' }} onClick={() => onCancelResumeAutomation?.()}>
						Cancel
					</Button>
				) : (
					<ReminderSelector
						isChecked={isReminderOn}
						onCheck={onReminderCheckToggle}
						onSelectDate={onDateChangeReminder}
						selectedDate={reminderDate}
						dateOptions={ReminderDateOptions}
						disabled={disabled}
					/>
				)}
				<Tooltip
					title={'Please select a date'}
					open={isSendButtonTooltipOn}
					onVisibleChange={onTooltipVisibleChange}
				>
					<Button
						type="primary"
						style={{ height: '40px' }}
						disabled={isSendDisabled}
						onClick={handleSubmit}
					>
						{isInResumeAutomationMode ? (
							<Space>
								<PlayCircleFilled />
								Resume automation
							</Space>
						) : (
							<Space>
								<SendOutlined />
								Send
							</Space>
						)}
					</Button>
				</Tooltip>
			</Space>
		</Row>
	);
}
