import { Button, Space, Tooltip } from 'antd';
import { useState } from 'react';
import moment, { Moment } from 'moment/moment';
import {
	TextEditorContextActions,
	useTextEditorContext,
} from '@copilot/common/components/forms/textEditor/context';
import { isEmptyString } from '@copilot/common/utils/common';
import { disablePrevDates } from '@copilot/common/utils';
import { FULL_DATE_TIME_FORMAT } from '@copilot/common/utils/dateFormat';
import { CopilotDatePicker } from '@copilot/common/components/input/dates';

type ScheduledMessageFooterPropsType = {
	/** Callback when the user submits a scheduled message */
	onScheduledSubmit: (reply: string, date: Moment, templateId?: string) => unknown;
	/** Whether to show a submitting state for the component */
	isSubmitting?: boolean;
};

/**
 * [Presentational] Text Editor footer with Schedule Message Feature
 * @param props
 * @constructor
 */
export function TextEditorScheduledMessageFooter(props: ScheduledMessageFooterPropsType) {
	const { onScheduledSubmit, isSubmitting } = props;
	const { text, dispatchUpdate } = useTextEditorContext();
	const [selectedDate, setSelectedDate] = useState<Moment>();
	const [isSendButtonTooltipOn, setIsSendButtonTooltipOn] = useState<boolean>(false);

	const isScheduledDisabled = !selectedDate || isEmptyString(text);

	const onChangeDate = (date: moment.Moment | null) => {
		if (!date) {
			// resets date when datepicker is reset
			setSelectedDate(undefined);
		}
	};

	const handleSendButtonTooltip = (visible: boolean) => {
		setIsSendButtonTooltipOn(visible && !selectedDate);
	};

	const handleScheduledSubmit = (message: string, dateSelected?: Moment) => {
		if (onScheduledSubmit && dateSelected) {
			onScheduledSubmit(message, dateSelected, undefined);
			dispatchUpdate({ type: TextEditorContextActions.reset });
			setSelectedDate(undefined);
		}
	};

	return (
		<Space style={{ justifyContent: 'space-between', width: '100%' }}>
			<CopilotDatePicker
				showTime={{ defaultValue: moment('08:00:00', 'HH:mm:ss') }}
				format={FULL_DATE_TIME_FORMAT}
				placeholder="Select Time"
				onOk={(date) => setSelectedDate(date)}
				onChange={(date) => onChangeDate(date)}
				style={{ marginRight: '0.5em', width: '250px' }}
				disabledDate={disablePrevDates}
				showNow={false}
				value={selectedDate}
			/>

			<Tooltip
				title="Please select a date"
				visible={isSendButtonTooltipOn}
				onVisibleChange={handleSendButtonTooltip}
			>
				<Button
					type="primary"
					disabled={isScheduledDisabled}
					loading={isSubmitting}
					onClick={() => handleScheduledSubmit(text, selectedDate)}
					size="large"
				>
					Schedule
				</Button>
			</Tooltip>
		</Space>
	);
}
