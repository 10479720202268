import { ReactNode, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { DEFAULT_DATE_FORMAT } from '@copilot/common/utils/dateFormat';
import moment, { Moment } from 'moment';
import { ClockCircleOutlined, CheckOutlined, EditFilled } from '@ant-design/icons';
import { disablePrevDates } from '@copilot/common/utils';
import { CSSProperties } from 'styled-components';
import { ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { CopilotDateType } from '@copilot/common/components/input/dates';
import ButtonWithTooltip from './buttonToolTip';
import { getRelativeReminderDateMenuItems } from '@copilot/common/utils/dateUtil';
import ReminderPicker, { type ReminderPickerProps } from '../reminderPicker/reminderPicker';
import { SET_REMINDER_BUTTON_TEXT } from '@copilot/common/components/drawer/contactDrawerV2/infoPanelV2/constants';

type DropdownReminderSelectorProps = {
	onSetReminderClick?: () => void;
	onUpdate: (date: Moment, isNewReminder: boolean) => void;
	onCancel: () => void;
	selectedDate?: Moment;
	isReminderSet: boolean;
	isIconOnly?: boolean;
	buttonType?: ButtonType;
	buttonStyle?: CSSProperties;
	buttonClass?: string;
	buttonIcon?: ReactNode;
	size?: SizeType;
	loading?: boolean;
	alignment?: ReminderPickerProps['alignment'];
	disabled?: boolean;
};

/**
 * Reminder selector with dropdown options of Edit and Cancel
 * @param onSetReminderClick callback when set reminder button is clicked
 * @param onUpdate callback on update
 * @param onCancel callback on cancel
 * @param selectedDate selected date for reminder
 * @param isReminderSet whether reminder has been set already
 * @param isIconOnly {boolean} whether reminder has been set already
 * @param buttonType type of button
 * @param buttonStyle styling of button
 * @param buttonIcon {ReactNode} the icon for the button
 */
function DropdownReminderSelector({
	selectedDate,
	onSetReminderClick,
	onUpdate,
	onCancel,
	isReminderSet,
	isIconOnly = false,
	buttonType = 'primary',
	buttonIcon = <ClockCircleOutlined />,
	buttonStyle,
	size,
	loading,
	buttonClass,
	alignment,
	disabled
}: DropdownReminderSelectorProps) {
	const isGhostButton = buttonType === 'primary';
	const [isDatePickerOn, setIsDatePickerOn] = useState<boolean>(false);

	function onToggleDatePicker() {
		setIsDatePickerOn((isOn) => !isOn);
	}

	function handleSetReminderClick() {
		onSetReminderClick?.();
	}

	function onSetReminderDate(date: Moment) {
		onUpdate(date, !isReminderSet);
		onToggleDatePicker();
	}

	if (isDatePickerOn) {
		return (
			<ReminderPicker
				selectedDate={selectedDate}
				onToggleDatePicker={onToggleDatePicker}
				disabledDates={disablePrevDates}
				isIconOnly={isIconOnly}
				buttonStyle={buttonStyle}
				onSetReminderDate={onSetReminderDate}
				onCancel={onToggleDatePicker}
				alignment={alignment}
			/>
		);
	}

	return (
		<>
			{isReminderSet ? (
				<Dropdown trigger={['click']} overlay={reminderMenu(onToggleDatePicker, onCancel)}>
					<ButtonWithTooltip
						disabled={disabled}
						isIconOnly={isIconOnly}
						ghost={isGhostButton}
						label={selectedDate?.format(DEFAULT_DATE_FORMAT) ?? 'Edit Reminder'}
						type={buttonType}
						style={buttonStyle}
						className={buttonClass}
						icon={buttonIcon}
						size={size}
						loading={loading}
						danger={selectedDate?.isBefore(moment())}
					/>
				</Dropdown>
			) : (
				<Dropdown
					trigger={['click']}
					overlay={getRelativeReminderDateMenuItems(
						onToggleDatePicker,
						(date: CopilotDateType) => onUpdate(date, !isReminderSet)
					)}
				>
					<ButtonWithTooltip
						disabled={disabled}
						isIconOnly={isIconOnly}
						ghost={isGhostButton}
						label={SET_REMINDER_BUTTON_TEXT}
						onClick={handleSetReminderClick}
						type={buttonType}
						style={buttonStyle}
						className={buttonClass}
						icon={buttonIcon}
						size={size}
						loading={loading}
					/>
				</Dropdown>
			)}
		</>
	);
}

export default DropdownReminderSelector;

function reminderMenu(toggleDatePicker: () => void, onCancel: () => void) {
	return (
		<Menu>
			<Menu.Item onClick={toggleDatePicker}>
				<EditFilled /> Edit
			</Menu.Item>
			<Menu.Item onClick={onCancel}>
				<CheckOutlined /> Mark as complete
			</Menu.Item>
		</Menu>
	);
}
