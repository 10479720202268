import React from 'react';
import { Card, Col, Row } from 'antd';
import styled, { CSSProperties } from 'styled-components';
import RadioCircle from '../radio/circle';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface ISelectableOptionProps {
	checked: boolean;
	title: string;
	bodyText?: React.ReactNode;
	faded?: boolean;
	onClick: () => void;
	buttonSize?: SizeType;
	customStyle?: CSSProperties;
}

const StyledCard = styled(Card)`
	border-radius: 10px !important;
	padding: 20px !important;
	cursor: pointer;
	width: 100%;

	&.selected {
		border: 1px solid #00bcd4;
	}

	&.faded {
		opacity: 0.6;
	}

	&.faded:hover {
		opacity: 1;
	}

	&:hover {
		border: 1px solid #00bcd4;
	}

	h3 {
		margin-bottom: 0;
	}
`;

/**
 * Represents a selectable card option with ability to set checked and faded appearance based on parent container
 * @param checked whether this card is checked
 * @param title title text
 * @param bodyText content text body
 * @param faded whether this card option is faded out
 * @param onClick callback method when card is clicked
 * @param buttonSize button size
 * @param customStyle custom style for card
 */
const CardOption: React.FC<ISelectableOptionProps> = (props) => {
	const { checked, title, bodyText, onClick, faded, buttonSize, customStyle } = props;

	return (
		<StyledCard
			onClick={onClick}
			style={{ ...customStyle }}
			className={checked ? 'selected' : faded ? 'faded' : ''}
		>
			<Row style={{ alignItems: 'center', marginBottom: '4px' }}>
				<Col style={{ paddingRight: '16px' }}>
					<RadioCircle checked={checked} size={buttonSize} />
				</Col>
				<Col>
					<h3>{title}</h3>
				</Col>
			</Row>
			<Row>
				<Col>
					<div>{bodyText}</div>
				</Col>
			</Row>
			{props.children && (
				<Row>
					<Col span={24}>{props.children}</Col>
				</Row>
			)}
		</StyledCard>
	);
};

export default CardOption;
