import React, { useEffect, useState, useCallback, useImperativeHandle } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import TagList from '@copilot/common/components/drawer/wrappers/contact/tags/list';
import TagSelector from '@copilot/common/components/forms/common/generics/tagselector';

const TagListWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: 8px;

	.${(props) => props.theme['@ant-prefix']}-tag {
		margin-bottom: 8px;
	}

	.${(props) => props.theme['@ant-prefix']}-btn {
		height: 22px;
		font-size: 14px;
	}
`;

export interface EditableTagListHandle {
	resetTagList: () => void;
}

interface EditableTagListProps {
	selectedTags: string[];
	availableTags: string[];
	editLabel?: string;
	placeholder?: string;
	onSaveTagList: (tags: string[]) => void;
}

/**
 * A tag list that can be toggled to a tag editor
 *
 * @param {string[]} selectedTags tags selected and displayed
 * @param {string[]} availableTags tag options to appear in the dropdown for the editor
 * @param {string} editLabel label for edit button
 * @param {string} placeholder placeholder text for tag input when no tags are selected
 * @param {function} onSaveTagList called when the user clicks save after editing the tag list
 */
const EditableTagList: React.ForwardRefRenderFunction<EditableTagListHandle, EditableTagListProps> =
	(props, ref) => {
		const {
			selectedTags,
			availableTags,
			editLabel = 'Edit',
			placeholder = '',
			onSaveTagList,
		} = props;
		const [isEditing, setIsEditing] = useState<boolean>(false);
		const [unsavedTags, setUnsavedTags] = useState<string[]>([]);

		useEffect(() => {
			setUnsavedTags(selectedTags);
		}, [selectedTags]);

		const onCloseTagList = useCallback(
			(saveResults: boolean) => {
				if (saveResults) {
					onSaveTagList(unsavedTags);
				} else {
					setUnsavedTags(selectedTags);
				}
				setIsEditing(false);
			},
			[unsavedTags, selectedTags, onSaveTagList]
		);

		// used for closing the tag list editor from outside of the component
		useImperativeHandle(ref, () => ({
			resetTagList() {
				onCloseTagList(false);
			},
		}));

		if (isEditing) {
			return (
				<div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
					<TagSelector
						selectedTags={unsavedTags}
						placeholder={placeholder}
						onTagUpdate={setUnsavedTags}
						availableTags={availableTags}
					/>
					<Button
						size="small"
						disabled={unsavedTags.length < 1}
						onClick={() => onCloseTagList(true)}
						style={{ marginLeft: '8px', flex: 0 }}
					>
						Save
					</Button>
				</div>
			);
		} else
			return (
				<TagListWrapper>
					<TagList tags={selectedTags} />
					<Button size="small" onClick={() => setIsEditing(true)}>
						{editLabel}
					</Button>
				</TagListWrapper>
			);
	};

export default React.forwardRef(EditableTagList);
