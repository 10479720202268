import React from 'react';
import { Col, Row } from 'antd';
import { createGlobalStyle } from 'styled-components';

interface PlainLayoutProps {}

const PlainLayoutGlobalStyle = createGlobalStyle`
	.clickable {
		cursor: pointer;
	}
`;

const PlainLayout: React.FC<PlainLayoutProps> = (props) => (
	<>
		<PlainLayoutGlobalStyle />
		<Row>
			<Col span={24}>{props.children}</Col>
		</Row>
	</>
);

export default PlainLayout;
