import TagSelector from '@copilot/common/components/forms/common/generics/tagselector';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Tag, Button, Flex } from 'antd';
import TagList from '@copilot/common/components/drawer/wrappers/contact/tags/list';
import styles from './tagsRow.module.less';
import {
	TAGS_EDIT_BUTTON_TEST_ID,
	TAGS_SAVE_BUTTON_TEST_ID,
} from '../../../contactDrawerV2/infoPanelV2/constants';

type TagsRowProps = {
	isOwner: boolean;
	contactTags: string[];
	showInput: () => void;
	availableTags: string[];
	setContactTags: (x: string[]) => void;
	onSaveTags: (contactTags: string[]) => void;
	inputVisible: boolean;
};

export default function TagsRow({
	isOwner,
	contactTags,
	showInput,
	availableTags,
	setContactTags,
	onSaveTags,
	inputVisible,
}: TagsRowProps) {
	function onTagClose(closed: string) {
		const remaining = contactTags.filter((tag) => tag !== closed);
		setContactTags(remaining);
		onSaveTags(remaining);
	}

	if (!isOwner) {
		return <></>;
	}

	return (
		<Flex vertical className={styles.tagsRowWrapper}>
			{inputVisible ? (
				<>
					<TagSelector
						availableTags={availableTags}
						selectedTags={contactTags}
						onTagUpdate={setContactTags}
						placeholder="Enter tags"
					/>
					<Button
						onClick={() => onSaveTags(contactTags)}
						size="small"
						className={styles.saveTagsButton}
						data-testid={TAGS_SAVE_BUTTON_TEST_ID}
					>
						<SaveOutlined />
						Save
					</Button>
				</>
			) : (
				<>
					<Tag
						onClick={showInput}
						className={styles.editTag}
						data-testid={TAGS_EDIT_BUTTON_TEST_ID}
					>
						<EditOutlined />
						Edit
					</Tag>
					<div>
						<TagList
							tags={contactTags}
							closable
							handleTagClose={onTagClose}
							className={styles.tagList}
						/>
					</div>
				</>
			)}
		</Flex>
	);
}
