import React, { ComponentProps } from 'react';
import { Button } from 'antd';
import { useApprovalStatusFormatter } from '@copilot/common/hooks/campaign';
import ActiveToggle from '@copilot/common/components/toggles/campaign/active';
import { CampaignApprovalStatus } from '@copilot/data/responses/interface';
import StatusFormatter, {
	StatusFormatIconType,
} from '@copilot/common/components/common/statusFormat';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import isNil from 'lodash/isNil';
import { useFetchV2 } from '@copilot/common/hooks/common';

interface CampaignStatusDisplayProps {
	approvalStatus: CampaignApprovalStatus;
	isEnabled: boolean;
	isPaused: boolean;
	statusIconSize?: string;
	statusCustomText?: string | null;
	checkedStatusText?: string;
	uncheckedStatusText?: string;
	statusToggleStyle?: React.CSSProperties | undefined;
	alignment?: ComponentProps<typeof StatusFormatter>['alignment']; // TODO: need to update
	onToggleChange: (value: boolean) => Promise<void>;
	onToggleClick?: SwitchChangeEventHandler;
	onActivateCampaign?: () => void;
	approvedDisplay?: JSX.Element;
}

/**
 * Campaign Status Display that shows a toggle for approved campaigns
 *
 * @param {CampaignApprovalStatus} approvalStatus the approval status of the campaign
 * @param {boolean} isEnabled whether the campaign is enabled
 * @param {boolean} isPaused whether the current campaign is paused
 * @param {string} statusIconSize size for the status icon, in pixels
 * @param {string} statusCustomtext custom text for the approval status
 * @param {string} checkedStatusText the text for the toggle's enabled status; 'Active` is used if not provided
 * @param {string} uncheckedStatusText the text for the toggle's disabled status; 'Disabled' is used if not provied
 * @param {CSSProperties} statusToggleStyle for overriding the default toggle style
 * @param {RowJustify} alignment alignment for the approval status text
 * @param {function} onToggleChange callback for approved campaigns when the toggle is changed
 * @param {function} onToggleClick callback for approved campaigns when the toggle is clicked
 * @param {function} onActivateCampaign callback for the optional Activate Campaign button
 * @param {JSX.Element} approvedDisplay alternate element to display for approved campaigns
 */
function CampaignStatusDisplay({
	approvalStatus,
	isEnabled,
	isPaused,
	statusIconSize = '15px',
	statusCustomText,
	checkedStatusText,
	uncheckedStatusText,
	statusToggleStyle,
	alignment = 'center',
	onToggleChange,
	onToggleClick,
	onActivateCampaign,
	approvedDisplay,
}: CampaignStatusDisplayProps) {
	const campaignApprovalStatusFormatter = useApprovalStatusFormatter(
		statusIconSize,
		approvalStatus,
		statusCustomText,
		alignment
	);

	const [{ isFetching: isStatusUpdating }, handleToggleChange] = useFetchV2(onToggleChange);

	if (isPaused && approvalStatus !== CampaignApprovalStatus.Disconnected) {
		return (
			<StatusFormatter
				iconSize={statusIconSize}
				iconType={StatusFormatIconType.PauseCircle}
				text="Paused"
				color="grey"
				alignment={alignment}
			/>
		);
	} else if (approvalStatus === CampaignApprovalStatus.Approved) {
		return isNil(approvedDisplay) ? (
			<ActiveToggle
				checked={isEnabled}
				onChange={(state) => {
					handleToggleChange(state);
				}}
				onClick={onToggleClick}
				loading={isStatusUpdating}
				style={statusToggleStyle}
				checkedChildren={checkedStatusText}
				unCheckedChildren={uncheckedStatusText}
			/>
		) : (
			approvedDisplay
		);
	} else
		return (
			<>
				{campaignApprovalStatusFormatter}
				{!isNil(onActivateCampaign) && (
					<Button onClick={onActivateCampaign}>Activate Campaign</Button>
				)}
			</>
		);
}

export default CampaignStatusDisplay;
