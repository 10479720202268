import { ConfigType } from '@copilot/data/config/types';

const Config: ConfigType = window.runtimeConfig;

export const BASE_URL = Config.apiURL;
export const TASK_URL = Config.statelessTaskURL;
export const LOGIN_TASK_URL = Config.loginTaskURL;
export const PAYMENT_URL = Config.paymentURL;
export const PAYMENT_PRODUCT_URL = Config.paymentProductURL;
export const USER_INTEREST_URL = Config.userInterestsURL;
export const CRM_ACCOUNT_API_URL = Config.crmAccountApiURL;

export namespace BACKEND_ROUTES {
	export const AUTHENTICATION = {
		Login: `${BASE_URL}/auth/login`,
		ResetPassword: `${BASE_URL}/auth/resetpassword`,
		SetPassword: `${BASE_URL}/auth/setpassword`,
		Identify: `${BASE_URL}/auth/identify`,
		ChangeOrg: `${BASE_URL}/auth/changeorg`,
	};

	export const CAMPAIGN = {
		Default: `${BASE_URL}/api/campaigns`,
		LinkedIn: `${BASE_URL}/api/linkedin/campaigns`,
		Create: `${BASE_URL}/api/linkedin/campaigns/prospecting`,
		IndividualUserCreate: `${BASE_URL}/api/linkedin/campaigns/individual/prospecting`,
		Onboard: `${BASE_URL}/api/linkedin/onboard/campaign`,
		NurtureOnboard: `${BASE_URL}/api/linkedin/nurture/onboard/campaign`,
		UpdateStatus: `${TASK_URL}/api/campaigns`,
	};

	export const INBOX = {
		Default: `${BASE_URL}/api/inbox`,
		V3: `${BASE_URL}/api/inbox/v3`,
		V4: `${BASE_URL}/api/inbox/v4`,
		Execute: `${TASK_URL}/api/linkedin/campaigns/executelinkedin`,
		Campaign: `${BASE_URL}/api/inbox/campaigns`,
		Thread: `${BASE_URL}/thread`,
	};

	export const OUTBOX = {
		Default: `${BASE_URL}/api/outbox`,
	};

	export const CONTACT = {
		Default: `${BASE_URL}/api/contacts`,
		Export: `${BASE_URL}/api/export/connections/list`,
	};

	export const LINKED_IN = {
		Onboard: `${BASE_URL}/api/linkedin/onboard`,
		NurtureOnboard: `${BASE_URL}/api/linkedin/nurture/onboard`,
		Login: `${LOGIN_TASK_URL}/api/linkedin/login`,
		Search: `${TASK_URL}/api/linkedin/search`,
		SendMessage: `${TASK_URL}/api/linkedin/sendmessage`,
		ResendMessages: `${TASK_URL}/api/linkedin/resendmessages`,
		DiscardMessages: `${TASK_URL}/api/linkedin/discardmessages`,
		SendInvites: `${TASK_URL}/api/linkedin/sendInvites`,
		InitMember: `${TASK_URL}/api/linkedin/campaigns`,
		MemberInfo: `${BASE_URL}/api/linkedin/campaigns`,
		OrgInfo: `${BASE_URL}/api/linkedin/organizations`,
		Members: `${BASE_URL}/api/linkedin/members`,
		LoginRoomName: `${TASK_URL}/api/linkedin/requestlogin`,
	};

	export const MESSAGE = {
		Default: `${BASE_URL}/api/limessages`,
	};

	export const NOTIFICATIONS = {
		Default: `${BASE_URL}/api/notification`,
		Settings: `${BASE_URL}/api/notification/settings`,
	};

	export const ORGANIZATION = {
		Default: `${BASE_URL}/api/organizations`,
	};

	export const TEMPLATE = {
		Default: `${BASE_URL}/api/templates`,
	};

	export const ORGANIZATION_MEMBER = {
		Default: `${BASE_URL}/api/members`,
		Me: `${BASE_URL}/api/members/me`,
		ResetMySalesNavErrorStatus: `${BASE_URL}/api/members/me/clear-sales-nav-error`,
		DisconnectLinkedIn: `${BASE_URL}/api/members/me/disconnect-linkedin`,
		ResetPassword: `${BASE_URL}/auth/requestresetpassword`,
	};

	export const ADMIN = {
		Organizations: `${BASE_URL}/api/admin/organizations`,
		OrgMembers: `${BASE_URL}/api/admin/orgmembers`,
		AdminMember: `${BASE_URL}/api/admin/adminmembers`,
		AdminMemberDetails: `${BASE_URL}/api/admin/adminmembers/details`,
		CS: `${BASE_URL}/api/admin/cs`,
		Client: `${BASE_URL}/api/admin/client`,
		Teams: `${BASE_URL}/api/admin/teams`,
		Instance: `${BASE_URL}/api/admin/instance`,
	};

	export const TASK = {
		Default: `${LOGIN_TASK_URL}/api/tasks`,
		Stateless: `${TASK_URL}/api/tasks`,
		LinkedIn: `${TASK_URL}/api/linkedin/tasks`,
	};

	export const SCHEDULE = {
		Default: `${BASE_URL}/api/schedules`,
	};

	export const CHANGES = {
		Default: `${BASE_URL}/api/organizations`,
	};

	export const SETTINGS = {
		FE: `${BASE_URL}/api/settings/fe`,
		SERVER: `${BASE_URL}/api/settings/fe/server`,
	};

	export const FILTER_TEMPLATES = {
		Default: `${BASE_URL}/api/filtertemplate`,
	};

	export const CATEGORY = {
		Campaign: `${BASE_URL}/api/campaigns/categories`,
	};

	export const BILLING = {
		Info: `${PAYMENT_URL}/api/billingInfo`,
		IndividualInfo: `${PAYMENT_PRODUCT_URL}/api/individualBillingInfo`,
		Products: `${PAYMENT_PRODUCT_URL}/api/mainProducts`,
		Coupons: `${PAYMENT_PRODUCT_URL}/api/coupons`,
		PreviewSubscription: `${PAYMENT_PRODUCT_URL}/api/invoices/preview`,
		Subscription: `${PAYMENT_PRODUCT_URL}/api/subscriptions`,
	};

	export const ATTENTION_ITEMS = {
		Default: `${BASE_URL}/api/attentionItems`,
	};

	export const CONNECTIONS_VIEW = {
		Default: `${BASE_URL}/api/connections`,
	};

	export const SELF_SERVE = {
		Default: `${BASE_URL}/api/settings/fe/selfServe`,
	};

	// TODO: sync up about route once BE is finalized
	export const USER_INTERESTS = {
		Default: `${USER_INTEREST_URL}/api/interests`,
	};

	export const DEFAULT_TEMPLATES = {
		Default: `${BASE_URL}/api/campaign/messagingStrategy`,
	};

	export const PERSONALITY_INSIGHTS = {
		Credits: `${BASE_URL}/api/personality/credits`,
		Analysis: `${BASE_URL}/api/personality/analysis`,
		CommunicationStyle: `${BASE_URL}/api/personality/communicationStyle`,
	};

	export const SMART_REPLY = {
		Default: `${BASE_URL}/api/smartReply`,
	};

	export const AI_FEATURE = {
		Default: `${BASE_URL}/api/aiFeature`,
	};

	export const ACCOUNT_FEATURE = {
		Default: `${BASE_URL}/api/account`,
	};

	export const CRM_ACCOUNT_API = {
		Default: CRM_ACCOUNT_API_URL,
		GetCustomerToken: `${CRM_ACCOUNT_API_URL}/token`,
		GetAccessToken: `${CRM_ACCOUNT_API_URL}/auth`,
		SsoMethods: `${CRM_ACCOUNT_API_URL}/sso`,
	};
}

const baseB2CRoute = `https://${Config.b2cLoginDomainName}/${Config.b2cTenantId}`;
export const B2CCustomPolicyRoutes = {
	SignIn: `${baseB2CRoute}/${Config.b2cSignInPolicy}`,
	SignUp: `${baseB2CRoute}/${Config.b2cSignupPolicy}`,
	ChangeToken: `${baseB2CRoute}/${Config.b2cChangeTokenPolicy}`,
	Impersonation: `${baseB2CRoute}/${Config.b2cImpersonationPolicy}`,
};
