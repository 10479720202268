import React, {
	useCallback,
	useState,
	useEffect,
	useMemo,
	ReactNode,
	useRef,
	ComponentProps,
} from 'react';
import MessageEditorTokens from './tokens';
import TextArea, { TextAreaRef } from 'antd/lib/input/TextArea';
import MessageEditorTiming, { MINIMUM_MINUTES, RECOMMENDED_TIMING } from './timing';
import { Button, Col, Row, Popconfirm, Checkbox, Input } from 'antd';
import { LinkedInManager, TemplateManager } from '@copilot/data';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useSelector, useDispatch } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { MessageEditorNameTokens } from '@copilot/common/utils/constant';
import { Config } from '@copilot/common/config';
import { NodeTimerPeriodStrings } from '@copilot/common/store/models/const';
import {
	NodeTimerPeriodEnum,
	NodeTimerPeriodStringEnum,
} from '@copilot/common/store/models/const/enum';
import { MessageTemplateActions } from '@copilot/common/store/actions';
import { AgencyCode } from '@copilot/common/config/constant';
import { inputAndFocusAtCaret } from '@copilot/common/utils/textArea/textAreaInsert';

export const defaultWarningText = `
	This follow up message will be sent to prospects who've yet to reply to you. 
	Create a new campaign if you're reaching out to a new target audience.
`;

interface IMessage {
	nodeId?: string;
	period: number;
	text: string;
	time: number;
}

interface ConfirmSaveProps {
	title: ComponentProps<typeof Popconfirm>['title'];
	okText: ComponentProps<typeof Popconfirm>['okText'];
	cancelText: ComponentProps<typeof Popconfirm>['cancelText'];
	onSave: () => void;
	onCancel?: () => void;
	disabled: boolean;
}

/**
 * Component for Save Message button and confirmation
 * @param title title to display in confirmation
 * @param okText text for ok
 * @param cancelText text for cancel
 * @callback onSave called on save
 * @callback [onCancel] called on cancel
 * @param disabled whether message is disabled from saving
 */

const ConfirmSave: React.FC<ConfirmSaveProps> = (props) => {
	const { title, okText, cancelText, onSave, onCancel, disabled } = props;

	return (
		<Popconfirm
			title={title}
			onConfirm={onSave}
			onCancel={onCancel}
			okText={okText}
			cancelText={cancelText}
			disabled={disabled}
			overlayStyle={{ maxWidth: '40%' }}
		>
			<Button type="primary" disabled={disabled}>
				Save
			</Button>
		</Popconfirm>
	);
};
interface MessageEditorProps {
	charLimit?: number | null;
	hideTiming?: boolean;
	messageTitle?: string;
	message: IMessage;
	onUpdate?: (message: IMessage) => void;
	onCancel?: () => void;
	onSave?: (message: IMessage) => void;
	onDelete?: () => void;
	rows?: number;
	recommendedTime?: number;
	recommendedPeriod?: number;
	campaignId?: string;
	hideWarning?: boolean;
	warningMessage?: ReactNode;
	prevNodeId?: string;
	timeUnits?: NodeTimerPeriodStringEnum[];
	timingHeader?: string;
	hideTerminationSave?: boolean;
}

/**
 * ConfirmationCopy object used to specify copy for confirmation popover
 */
interface ConfirmationCopy {
	// Confirmation copy
	text: ReactNode;
	// Button copy for the confirmation prompt
	buttonText: { okText: string; cancelText: string };
}

/**
 * Editor for Message
 *
 * @param {IMessage} message message we want to edit
 * @param {number | null} [charLimit] character limit on message content
 * @param {boolean} [hideTiming=false] whether we want to hide message timing editor
 * @param {string} [messageTitle=''] message title
 * @callback [onUpdate] called on update
 * @callback [onCancel] called on cancel
 * @callback [onSave] called on save
 * @callback [onDelete] called on delete
 * @param {number} [rows=5] number of rows displayed on message textarea
 * @param {number} [recommendedTime=RECOMMENDED_TIMING.time] recommended time displayed for message timing editor
 * @param {number} [recommendedPeriod=RECOMMENDED_TIMING.period] recommended period displayed for message timing editor
 * @param {string} [campaignId=''] campaignId
 * @param {boolean} [hideWarning=false] whether to hide warning when saving message. specifically used for saving with node termination or without.
 * @param {ReactNode} [warningMessage] warning message to display when creating new message
 * @param {string} [prevNodeId] nodeId of previous node
 * @param {NodeTimerPeriodStringEnum[]} [timeUnits] available time units we can select for message timing editor
 * @param {string} [timingHeader='Follow Up Timing'] header for message timing editor
 * @param {boolean} [hideTerminationSave=false] whether to hide save and terminate when saving message
 *
 */

//TODO: CPD-2542 Convert into a Presentational Component
const MessageEditor: React.FC<MessageEditorProps> = (props) => {
	const {
		hideTiming = false,
		messageTitle = '',
		message,
		charLimit,
		onCancel,
		onUpdate,
		onSave,
		onDelete,
		rows = 5,
		recommendedTime = RECOMMENDED_TIMING.time,
		recommendedPeriod = RECOMMENDED_TIMING.period,
		campaignId = '',
		hideWarning = false,
		warningMessage,
		prevNodeId = '',
		timingHeader = 'Follow up send times',
		timeUnits = [],
		hideTerminationSave = false,
	} = props;
	const storeDispatch = useDispatch();
	const isInitialRender = useRef(true);
	const textAreaRef = useRef<TextAreaRef>(null);
	const [unsavedMessage, setUnsavedMessage] = useState<string>(message.text ?? '');
	const [unsavedTiming, setUnsavedTiming] = useState<number>(
		message.time != -1 ? message.time : recommendedTime
	);
	const [unsavedPeriod, setUnsavedPeriod] = useState<number>(
		message.period != -1 ? message.period : recommendedPeriod
	);
	const [isMakeTemplateChecked, setIsMakeTemplateChecked] = useState<boolean>(false);

	const isMessageInvalid = useMemo(
		() => unsavedMessage.trim() === '' || !!(charLimit && unsavedMessage.length > charLimit),
		[unsavedMessage, charLimit]
	);

	const toggleIsMakeTemplateChecked = () => {
		setIsMakeTemplateChecked((state) => !state);
	};

	//To display selected template in textarea
	useEffect(() => {
		if (message.text && unsavedMessage !== message.text) setUnsavedMessage(message.text);
	}, [message.text]);

	const activeAdmin = useSelector(OrganizationMemberSelectors.getAdminMember);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);

	const createQuickResponse = useCallback(() => {
		const organizationId = activeMember?.organizationId;
		const orgMemberId = activeMember?.id;
		if (organizationId && orgMemberId) {
			const template = {
				organizationId,
				orgMemberId,
				campaignIds: [campaignId],
				name: messageTitle,
				message: unsavedMessage,
			};
			TemplateManager.createTemplateMessage(template)
				.then((templateResponse) => {
					storeDispatch(MessageTemplateActions.updateOne(templateResponse));
					notificationManager.showSuccessNotification({
						message: 'Quick Response created',
						description: '',
					});
				})
				.catch(() => {
					notificationManager.showErrorNotification({
						message: 'Failed to create quick response',
						description: 'Please try again',
					});
				});
		} else {
			notificationManager.showErrorNotification({
				message: 'Failed to create quick response',
				description: 'Please try again',
			});
		}
	}, [activeMember, campaignId, messageTitle, unsavedMessage]);

	useEffect(() => {
		if (isInitialRender.current) {
			isInitialRender.current = false;
		} else {
			onUpdate?.({
				nodeId: message.nodeId,
				period: unsavedPeriod,
				text: unsavedMessage,
				time: unsavedTiming,
			});
		}
	}, [unsavedMessage, unsavedTiming, unsavedPeriod, message.nodeId]);

	const handleMessageChange = useCallback<
		NonNullable<React.ComponentProps<typeof TextArea>['onChange']>
	>((event) => {
		const { value } = event.currentTarget;
		setUnsavedMessage(value);
	}, []);
	const handleSave = useCallback(() => {
		const hasTimingError =
			!hideTiming &&
			unsavedPeriod === NodeTimerPeriodEnum.Minutes &&
			unsavedTiming < MINIMUM_MINUTES;
		if (hasTimingError) {
			notificationManager.showErrorNotification({
				message: 'Message Failed to Save',
				description: `You must wait at least ${MINIMUM_MINUTES} minutes to follow up`,
			});
			setUnsavedTiming(MINIMUM_MINUTES);
		} else {
			onSave?.({
				nodeId: message.nodeId,
				period: unsavedPeriod,
				text: unsavedMessage,
				time: unsavedTiming,
			});
			if (isMakeTemplateChecked) {
				createQuickResponse();
			}
		}
	}, [
		onSave,
		createQuickResponse,
		hideTiming,
		unsavedMessage,
		unsavedTiming,
		unsavedPeriod,
		message.nodeId,
		isMakeTemplateChecked,
	]);

	const handleDelete = useCallback(() => {
		onDelete?.();
	}, [onDelete]);

	const handleTokenClick = useCallback<
		React.ComponentProps<typeof MessageEditorTokens>['onTokenClick']
	>(
		(token) => {
			const startIndex =
				textAreaRef.current?.resizableTextArea?.textArea?.selectionStart ??
				unsavedMessage.length;
			inputAndFocusAtCaret(token.value, textAreaRef.current as HTMLTextAreaElement);
			setUnsavedMessage(
				`${unsavedMessage.slice(0, startIndex)}${token.value}${unsavedMessage.slice(
					startIndex
				)}`
			);
		},
		[unsavedMessage]
	);

	const handleSaveWithTermination = useCallback(() => {
		const prevNode = [];
		if (prevNodeId) prevNode.push(prevNodeId);
		LinkedInManager.terminateMessageAutomationByCampaign(campaignId, prevNode)
			.then(() => {
				notificationManager.showSuccessNotification({
					message: 'Message Automation Terminated',
				});
			})
			.catch(() => {
				notificationManager.showErrorNotification({
					message: 'Stop Message Automation Failed',
				});
			});
		handleSave();
	}, [campaignId, prevNodeId, handleSave]);

	const confirmationCopy = useMemo<ConfirmationCopy>(() => {
		let buttonText = { okText: 'Save', cancelText: 'Cancel' };
		let text: ReactNode = '';
		if (activeAdmin) {
			if (hideTerminationSave)
				text =
					'Be advised you are updating the sequence and this could cause issues with the flow of messaging for some prospects';
			else {
				text =
					'Would the customer like all prospects currently in the automation to receive this message? Clicking No will end the automation and apply the follow up to all new conversations';
				buttonText = { okText: 'Yes', cancelText: 'No' };
			}
		} else if (warningMessage) {
			text = warningMessage;
		} else if (!Config.isAgency) {
			text = defaultWarningText;
		} else {
			switch (Config.agencyCode) {
				case AgencyCode.cleverly:
					text = `
						Would you like all prospects currently in the automation to receive this message? 
						Clicking No will end the automation and apply the follow up to all new conversations
					`;
					buttonText = { okText: 'Yes', cancelText: 'No' };
					break;
				case AgencyCode.prosocial:
					text = `
						Double check your message for any spelling or grammatical errors. 
						If you added a new followup message, saving this will cause the new message
						to be sent to all the prospects who have not replied.
					`;
					break;
				default:
					throw TypeError('Pop confirm title not found');
			}
		}
		return { buttonText, text };
	}, [warningMessage, hideTerminationSave, activeAdmin]);

	const recommendedTimePeriod = useMemo(() => {
		const recommendedTimePeriodText =
			NodeTimerPeriodStrings[NodeTimerPeriodEnum[recommendedPeriod]].toLowerCase();
		const singularRecommendTimePeriodText = recommendedTimePeriodText.substring(
			0,
			recommendedTimePeriodText.length - 1
		);
		// display hour instead of hours
		return recommendedTime > 1 ? recommendedTimePeriodText : singularRecommendTimePeriodText;
	}, [recommendedTime]);

	return (
		<>
			<Row>
				<Col>
					{charLimit && (
						<p style={{ color: unsavedMessage.length > charLimit ? 'red' : 'black' }}>
							{`Character limit ${unsavedMessage.length} / ${charLimit}`}
						</p>
					)}
				</Col>
			</Row>
			<Row>
				<Col span={24} style={{ backgroundColor: 'white' }}>
					<Input.TextArea
						value={unsavedMessage}
						onChange={handleMessageChange}
						ref={textAreaRef}
						rows={rows}
					/>
				</Col>
			</Row>
			<br />
			<Row>
				<Col>
					<p>
						Use these buttons to pull a prospect's name directly from their LinkedIn
						profile to personalize your messages.
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<MessageEditorTokens
						tokens={MessageEditorNameTokens}
						onTokenClick={handleTokenClick}
					/>
				</Col>
			</Row>
			<br />
			{onSave && !!messageTitle && (
				<Row>
					<Col>
						<Checkbox
							checked={isMakeTemplateChecked}
							onChange={toggleIsMakeTemplateChecked}
						>
							<span>Save message as a new Quick Response</span>
						</Checkbox>
					</Col>
				</Row>
			)}
			<br />
			{!hideTiming && (
				<>
					<Row>
						<Col>
							<h3>{timingHeader}</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								Leave at least {recommendedTime} {recommendedTimePeriod}
								&nbsp;to send a follow up message
							</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<MessageEditorTiming
								period={unsavedPeriod}
								time={unsavedTiming}
								onPeriodUpdate={setUnsavedPeriod}
								onTimeUpdate={setUnsavedTiming}
								timeUnits={timeUnits}
							/>
						</Col>
					</Row>
				</>
			)}
			{onSave && (
				<Row justify="end" gutter={16}>
					{onDelete && (
						<Col>
							<Button onClick={handleDelete} danger>
								Delete
							</Button>
						</Col>
					)}
					<Col>
						<Button onClick={onCancel}>Cancel</Button>
					</Col>
					<Col>
						{hideWarning ? (
							<Button type="primary" disabled={isMessageInvalid} onClick={handleSave}>
								Save
							</Button>
						) : (
							<ConfirmSave
								title={confirmationCopy.text}
								okText={confirmationCopy.buttonText.okText}
								cancelText={confirmationCopy.buttonText.cancelText}
								onSave={handleSave}
								onCancel={
									activeAdmin && !hideTerminationSave
										? handleSaveWithTermination
										: undefined
								}
								disabled={isMessageInvalid}
							/>
						)}
					</Col>
				</Row>
			)}
		</>
	);
};

export default MessageEditor;
