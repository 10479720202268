import React, { PropsWithChildren, Suspense } from 'react';

/** HOC to wrap a component with React.Suspence
 *  TODO: Replace the fallback with a custom icon
 */
export const withSuspense =
	<T,>(Component: React.FC<T>) =>
	(props: PropsWithChildren<T>) =>
		(
			<Suspense fallback={<div>Loading...</div>}>
				<Component {...props} />
			</Suspense>
		);
