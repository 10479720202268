import { Button, Flex, Form, Input, InputRef, Popconfirm, Space, Typography, Alert } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
	INMAIL_CHARACTER_COUNT_RECOMMENDATION_MESSAGE,
	INMAIL_FORM_KEYS,
	INMAIL_MESSAGE_CHARACTER_LIMIT,
	INMAIL_MESSAGE_RECOMMENDED_CHARACTOR_LIMIT,
	INMAIL_SUBJECT_CHARACTER_LIMIT,
} from './constants';
import styles from './messageEditorPanel.module.less';
import { isEmptyString } from '@copilot/common/utils/common';

type SavePayload = Readonly<{
	[INMAIL_FORM_KEYS.subject]: string;
	[INMAIL_FORM_KEYS.message]: string;
}>;

type MessageEditorPanelProps = Readonly<{
	/**
	 * Initial Message text to load
	 */
	initialMessage: string;
	/**
	 * Initial subject to load
	 */
	initialSubject: string;
	/**
	 * Whether the editor is initially editing
	 */
	initialIsEditing?: boolean;
	/**
	 * Name of the node
	 */
	nodeName: string;
	/**
	 * The id of the node
	 */
	nodeId?: string;
	/**
	 * Callback called when saving messages
	 */
	onSave: (payload: SavePayload) => unknown;
	/**
	 * Whether the editor is currently saving
	 */
	isSaving?: boolean;
	/**
	 * Callback called when deleting a node
	 */
	onDelete?: () => unknown;
	/**
	 * Whether we can delete the node
	 */
	isDeletable?: boolean;
	/**
	 * Whether the editor is editable
	 */
	readonly?: boolean;
}>;

/**
 * Message editor panel for
 * @returns
 */
export function MessageEditorPanel({
	initialMessage,
	initialSubject,
	initialIsEditing = false,
	nodeName,
	onSave,
	isSaving = false,
	onDelete,
	isDeletable = false,
	readonly = false,
}: MessageEditorPanelProps) {
	const messageRef = useRef<TextAreaRef>(null);
	const subjectRef = useRef<InputRef>(null);

	const [form] =
		Form.useForm<{ [INMAIL_FORM_KEYS.subject]: string; [INMAIL_FORM_KEYS.message]: string }>();
	const subjectValue = Form.useWatch(INMAIL_FORM_KEYS.subject, form);
	const subjectCharCount = subjectValue?.length ?? 0;
	const isSubjectOverLimit = subjectCharCount > INMAIL_SUBJECT_CHARACTER_LIMIT;
	const messageValue = Form.useWatch(INMAIL_FORM_KEYS.message, form);
	const messageCharCount = messageValue?.length ?? 0;
	const isMessageOverLimit = messageCharCount > INMAIL_MESSAGE_CHARACTER_LIMIT;
	const isMessageOverRecommendation =
		messageCharCount > INMAIL_MESSAGE_RECOMMENDED_CHARACTOR_LIMIT;

	const isFormValid =
		form.isFieldsTouched() &&
		!isMessageOverLimit &&
		!isSubjectOverLimit &&
		!isEmptyString(messageValue) &&
		!isEmptyString(subjectValue);

	const hasInitialMessage = !isEmptyString(initialSubject) && !isEmptyString(initialMessage);
	const [lastFocused, setLastFocused] = useState<string>(INMAIL_FORM_KEYS.message);
	const [isEditing, setIsEditing] = useState<boolean>(initialIsEditing);

	const [showMessageLengthWarning, setShowMessageLengthWarning] = useState(false);

	useEffect(() => {
		if (isMessageOverRecommendation) setShowMessageLengthWarning(isMessageOverRecommendation);
	}, [isMessageOverRecommendation]);

	function getInsertedText(
		textToInsert: string,
		existingText: string,
		element: HTMLInputElement | HTMLTextAreaElement
	): string {
		const selectionStart = element.selectionStart ?? 0;
		const selectionEnd = element.selectionEnd ?? 0;
		return [
			existingText.substring(0, selectionStart),
			textToInsert,
			existingText.substring(selectionEnd, existingText.length),
		].join('');
	}

	/**
	 * Insert a token into the message
	 * @param textToInsert The token to insert
	 */
	function insertMessage(textToInsert: string): void {
		messageRef.current?.focus();
		const element = messageRef.current?.resizableTextArea?.textArea;
		if (isNil(element)) throw new Error('Text area not displayed');
		const existingText: string = form.getFieldValue(INMAIL_FORM_KEYS.message);
		const newText = getInsertedText(textToInsert, existingText, element);
		form.setFieldValue(INMAIL_FORM_KEYS.message, newText);
	}

	/**
	 * Insert a token into the subject
	 * @param textToInsert The token to insert
	 */
	function insertSubject(textToInsert: string): void {
		subjectRef.current?.focus();
		const element = subjectRef.current?.input;
		if (isNil(element)) throw new Error('Text area not displayed');
		const existingText: string = form.getFieldValue(INMAIL_FORM_KEYS.subject);
		const newText = getInsertedText(textToInsert, existingText, element);
		form.setFieldValue(INMAIL_FORM_KEYS.subject, newText);
	}

	/**
	 * Paste a token into the texteditor
	 * @param tokenInput The token to paste
	 */
	function handleTokenClick(tokenInput: string) {
		if (lastFocused === INMAIL_FORM_KEYS.message) insertMessage(tokenInput);
		else insertSubject(tokenInput);
	}

	/**
	 * Cancel editing
	 */
	function handleCancel() {
		setIsEditing(false);
		form.resetFields();
	}

	/**
	 * Helper to determine validation status for message editor
	 */
	function getMessageEditorValidationStatus() {
		if (isMessageOverLimit) return 'error';
		if (isMessageOverRecommendation && showMessageLengthWarning) return 'warning';
		return undefined;
	}

	/**
	 * Helper to determine the validation status for the message character counter
	 */
	function getCharacterCounterValidationStatus() {
		if (isMessageOverLimit) return 'danger';
		if (isMessageOverRecommendation) return 'warning';
		return undefined;
	}

	return (
		<>
			{!readonly && isEditing ? (
				<Flex vertical gap="middle">
					<Typography.Title level={5}>{nodeName}</Typography.Title>
					<Typography.Text>
						Use these buttons to pull a prospect's name directly from their LinkedIn
						profile to personlize your messages:
					</Typography.Text>
					<Space>
						<Button onClick={() => handleTokenClick('{firstname}')}>
							{'{firstname}'}
						</Button>
						<Button onClick={() => handleTokenClick('{lastname}')}>
							{'{lastname}'}
						</Button>
					</Space>
					<Flex vertical>
						<Form
							form={form}
							layout="vertical"
							onFinish={onSave}
							initialValues={{ message: initialMessage, subject: initialSubject }}
						>
							<Flex justify="space-between">
								<Typography.Text>InMail Subject Line (required)</Typography.Text>
								<Space>
									<Typography.Text
										type={isSubjectOverLimit ? 'danger' : undefined}
									>
										Character limit {subjectCharCount} /{' '}
										{INMAIL_SUBJECT_CHARACTER_LIMIT}
									</Typography.Text>
								</Space>
							</Flex>
							<Form.Item
								name={INMAIL_FORM_KEYS.subject}
								validateStatus={isSubjectOverLimit ? 'error' : undefined}
							>
								<Input
									placeholder=""
									ref={subjectRef}
									onFocus={() => setLastFocused(INMAIL_FORM_KEYS.subject)}
								/>
							</Form.Item>
							<Flex justify="space-between">
								<Typography.Text>InMail Message (required)</Typography.Text>
								<Space>
									<Typography.Text type={getCharacterCounterValidationStatus()}>
										Character limit {messageCharCount} /{' '}
										{INMAIL_MESSAGE_CHARACTER_LIMIT}
									</Typography.Text>
								</Space>
							</Flex>
							{showMessageLengthWarning && (
								<Alert
									type={'warning'}
									showIcon
									closable
									onClose={() => setShowMessageLengthWarning(false)}
									className={styles.warningMessage}
									message={INMAIL_CHARACTER_COUNT_RECOMMENDATION_MESSAGE}
								/>
							)}
							<div className={styles.editor}>
								<Form.Item
									name={INMAIL_FORM_KEYS.message}
									className={styles.inputBox}
									validateStatus={getMessageEditorValidationStatus()}
								>
									<Input.TextArea
										rows={10}
										placeholder=""
										ref={messageRef}
										onFocus={() => setLastFocused(INMAIL_FORM_KEYS.message)}
									/>
								</Form.Item>
								<div className={styles.messageFormFooter}>
									Your signature will be added automatically
								</div>
							</div>
							<Form.Item>
								<Flex justify={'end'} gap={'small'}>
									{hasInitialMessage && (
										<Button type="default" onClick={handleCancel}>
											Cancel
										</Button>
									)}
									<Button
										disabled={isSaving || !isFormValid}
										loading={isSaving}
										type="primary"
										htmlType="submit"
									>
										Save
									</Button>
								</Flex>
							</Form.Item>
						</Form>
					</Flex>
				</Flex>
			) : (
				<Flex vertical gap={'small'}>
					<Typography.Title level={5}>
						<Space align="center">
							{nodeName}
							<Button
								size="small"
								disabled={readonly}
								onClick={() => setIsEditing(true)}
							>
								Edit
							</Button>
							{isDeletable && (
								<Popconfirm
									title={`Are you sure you want to delete ${nodeName}?`}
									okText="Yes"
									cancelText="No"
									onConfirm={onDelete}
									placement="rightBottom"
								>
									<Button size="small" danger type="primary">
										Delete
									</Button>
								</Popconfirm>
							)}
						</Space>
					</Typography.Title>
					<Typography.Text>{initialSubject}</Typography.Text>
					<Typography.Paragraph className={styles.message}>
						{initialMessage}
					</Typography.Paragraph>
				</Flex>
			)}
		</>
	);
}
