import { useMemo, useEffect, useState } from 'react';
import { DrawerProps } from 'antd/lib/drawer';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { useContact } from '@copilot/common/hooks/contact';
import { useContactLinkedinProfile } from '@copilot/common/hooks/linkedin';
import { ActivitySelectors } from '@copilot/common/store/selectors/activity';
import { useMessagesForLinkedinProfile } from '@copilot/common/hooks/activity';
import { OrmState } from 'redux-orm';
import { ContactConnectionSelectors } from '@copilot/common/store/selectors/contactConnection';
import { useContactConnection } from '@copilot/common/hooks/contactConnection';
import ErrorBoundary from '@copilot/common/components/containers/errorBoundary';
import { ContactConnectionActions } from '@copilot/common/store/actions';
import { useProspectDrawerTracking } from '@copilot/common/components/drawer/wrappers/contact/tracking';
import { arrayToMap } from '@copilot/common/utils';

import { MeetingDetails } from '@copilot/data/responses/interface';
import { useBookMeeting, useReminders } from '../data';
import {
	PipelineStateNames,
	useNewProspectDrawer,
	usePipelineState,
} from '@copilot/common/components/drawer/wrappers/contact/data/hooks';
import ContactDrawerLayout from '@copilot/common/components/drawer/wrappers/contact/layout';
import ContactDrawerContainer from '@copilot/common/components/drawer/wrappers/contact/container';
import { StaticBaseDrawer, meetingBookedDisabledStyles, meetingBookedStyles } from './styles';
import {
	CloseDrawerButton,
	dispatchContactUpdate,
	saveConnectionTagsAsOrgMember,
	saveTagsAsAdmin,
} from './util';
import InboxMessageSelectors from '@copilot/common/pages/inbox/data/selector';
import isNil from 'lodash/isNil';
import ThreadNavigator from './threadNavigator';
import ConversationPanel from './conversationPanel';
import styles from './drawer.module.less';
import { ContactDrawerTabs } from '../activity/constants';
import { AutomatedStep } from '@copilot/common/pages/inbox/ui/component/card/messageCard/types';
import { calcAutomatedSteps } from '@copilot/common/pages/inbox/ui/component/automationHelpers';
import { convertContactTags } from '@copilot/common/pages/connections';

type Props = DrawerProps & {
	/** id of contact */
	id: string;
	/** index of thread */
	threadIdx: number;
	/** id of orgMember */
	memberId: string;
	/** id of thread */
	threadId: string;
	/** drawer tab to open */
	tab?: ContactDrawerTabs;
	onClose?: () => void;
	/** handler for visited threads; will be called before onClose */
	visitedThreadsHandler?: (threadIds: string[]) => void;
	/** handler for threads with new messages sent; will be called before onClose */
	messageSentHandler?: () => void;
	/**
	 * whether the drawer is open from new inbox container that uses saga
	 * Dictates control flow on tag updates
	 * TODO: Clean up logics for inbox - https://cassia.atlassian.net/browse/COPILOT-3079
	 */
	isSagaInbox?: boolean;
};

/**
 * Drawer for contact
 * @param props
 */
function ContactDrawer({
	id: initContactId,
	threadIdx: initThreadIdx,
	memberId,
	threadId: initThreadId,
	tab,
	isSagaInbox,
	onClose,
	visitedThreadsHandler,
	messageSentHandler,
}: Props) {
	const [contactId, setContactId] = useState(initContactId);
	const [threadIdx, setThreadIdx] = useState(initThreadIdx);
	const [threadId, setThreadId] = useState(initThreadId);
	const [visitedThreadIds, setVisitedThreadIds] = useState(new Set([initThreadId]));
	const [hasSentMessage, setHasSentMessage] = useState(false);
	const messages = useSelector(InboxMessageSelectors);
	const [automatedSteps, setAutomatedSteps] = useState<AutomatedStep[] | undefined>(
		isNil(initThreadId)
			? undefined
			: calcAutomatedSteps(messages?.data?.[Number(initThreadIdx)]?.nextNodes)
	);
	const [selectedMemberId, setSelectedMemberId] = useState<string>(memberId);
	const numThreads = messages.data?.length ?? 1;

	const orgMember = useSelector((state) =>
		OrganizationMemberSelectors.getOrgMember(state, memberId)
	);
	const contactConnection = useSelector((state: { entities: OrmState<any> }) =>
		ContactConnectionSelectors.getContactConnectionById(state, contactId)
	);

	const activeAdmin = useSelector(OrganizationMemberSelectors.getAdminMember);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const isOrgAdmin = activeMember?.isOrgAdmin === true || activeMember?.isSysAdmin === true;
	const isOwner = activeMember?.id == memberId || isOrgAdmin || activeAdmin != null;

	const dispatch = useDispatch();

	const orgId = orgMember ? orgMember.organizationId : '';
	useEffect(() => {
		useContact(contactId);
	}, [contactId]);
	useContactConnection(orgId, contactId);
	const updateProspectDrawerTracking = useProspectDrawerTracking('Prospect Drawer');
	const isNewProspectDrawerEnabled = useNewProspectDrawer();

	const contactConnectionMapping = useMemo(
		() =>
			contactConnection?.connections &&
			arrayToMap(contactConnection.connections, (connection) => connection.orgMemberId),
		[contactConnection?.connections]
	);

	const selectedConnection = useMemo(
		() => contactConnectionMapping?.get(selectedMemberId),
		[contactConnectionMapping, selectedMemberId]
	);
	const contactProfile = useContactLinkedinProfile(orgId, contactId);
	const [contactTags, setContactTags] = useState<string[]>([]);

	const [conversationId, isMessagesLoading] = useMessagesForLinkedinProfile(
		selectedConnection?.orgMemberProfileId,
		selectedConnection?.profileId
	);
	const conversation = useSelector((state: { entities: OrmState<any> }) =>
		ActivitySelectors.getConversation(state, conversationId)
	);

	useEffect(() => {
		if (selectedConnection) setContactTags(selectedConnection.tags.map((t) => t.name));
	}, [selectedConnection]);

	function onMeetingBookedUpdate(connectionId: string, bookedResult: MeetingDetails) {
		const contactConnectionId = contactConnection?.id;
		if (isNil(contactConnectionId)) return;
		dispatch(
			ContactConnectionActions.updateOne({
				id: contactConnectionId,
				connections: contactConnection?.connections.map((con) => {
					if (con.id === connectionId) return { ...con, meeting: bookedResult };
					else return con;
				}),
			})
		);
	}

	function onSaveTags(tags: string[]): void {
		updateProspectDrawerTracking({ buttonClicked: 'Save Tags' });
		if (contactConnection) {
			const csOrgId = activeAdmin ? orgId : null;
			if (isOrgAdmin && selectedConnection?.id) {
				saveTagsAsAdmin({
					csOrgId,
					selectedConnection,
					contactTags: tags,
					contactConnection,
					dispatch,
					isSagaInbox,
					convertContactTags,
					threadId,
				});
			} else {
				saveConnectionTagsAsOrgMember({
					dispatch,
					csOrgId,
					contactTags: tags,
					threadId,
					convertContactTags,
					contactConnection,
					memberId,
				});
			}
		}
	}

	const [handleMeetingUpdate, bookMeetingMutationResult] = useBookMeeting(
		isNewProspectDrawerEnabled,
		selectedConnection?.id,
		onMeetingBookedUpdate
	);

	const reminders = useReminders(conversationId, updateProspectDrawerTracking);

	const flagStates = isNewProspectDrawerEnabled
		? meetingBookedStyles
		: meetingBookedDisabledStyles;

	const reminderDate = conversation?.snoozedDateTime
		? moment(conversation.snoozedDateTime)
		: undefined;

	const reminderProps = {
		reminderDate,
		useReminderFunctions: reminders,
	};

	const pipelineStates = usePipelineState(selectedConnection, conversation);

	function onGoToThread(newIdx: number) {
		if (isNil(messages.data)) return;
		if (newIdx >= 0 && newIdx < messages.data?.length) {
			setThreadIdx(newIdx);
			setSelectedMemberId(messages.data[newIdx].orgMemberId);
			setContactId(messages.data[newIdx].contactId);
			const newThreadId = messages.data[newIdx].threadId;
			setThreadId(newThreadId);
			setAutomatedSteps(calcAutomatedSteps(messages.data[newIdx].nextNodes));
			if (!visitedThreadIds.has(newThreadId)) {
				setVisitedThreadIds(new Set([...visitedThreadIds, newThreadId]));
			}
		}
	}

	function closeDrawer() {
		visitedThreadsHandler?.(Array.from(visitedThreadIds.values()));
		if (hasSentMessage) messageSentHandler?.();
		onClose?.();
	}

	return (
		<StaticBaseDrawer
			closable={false}
			width={flagStates.width}
			contentWrapperStyle={{ overflow: 'visible' }}
			{...{ currThreadId: threadId, tab, isSagaInbox, onClose }}
			className={styles.drawerBody}
		>
			<CloseDrawerButton onClose={closeDrawer} />
			{!isNil(threadIdx) && (
				<ThreadNavigator
					currThreadIdx={threadIdx}
					onGoToThread={onGoToThread}
					numThreads={numThreads}
				/>
			)}

			<ContactDrawerLayout>
				{isNewProspectDrawerEnabled ? (
					<ContactDrawerLayout.LeftPanel>
						<ContactDrawerContainer
							contactId={contactId}
							contactTags={contactTags}
							selectedOption={selectedMemberId}
							setContactTags={setContactTags}
							contactConnection={contactConnection}
							handleMemberSelect={setSelectedMemberId}
							handleContactUpdate={(updated) => {
								dispatchContactUpdate({
									id: updated.id,
									company: updated.company,
									email: updated.email,
									phoneNumber: updated.phoneNumber,
									position: updated.title,
								});
							}}
							isMeetingBooked={selectedConnection?.meeting.booked ?? false}
							isMeetingLoading={bookMeetingMutationResult?.loading ?? false}
							isReminderLoading={reminders.loading}
							handleMeetingClick={handleMeetingUpdate}
							reminderProps={reminderProps}
							contactAlias={contactProfile?.alias}
							profileId={contactProfile?.id}
							isOpenProfile={contactProfile?.isOpenProfile}
							pipelineProps={{
								hasMessaged: pipelineStates[PipelineStateNames.Messaged],
								hasReplied: pipelineStates[PipelineStateNames.Replied],
								hasBooked: pipelineStates[PipelineStateNames.Meeting],
							}}
							isOwner={isOwner}
							onSaveTags={onSaveTags}
						/>
					</ContactDrawerLayout.LeftPanel>
				) : null}
				<ContactDrawerLayout.RightPanel>
					<ErrorBoundary>
						<ConversationPanel
							contactId={contactId}
							contactTags={contactTags}
							isMessagesLoading={isMessagesLoading}
							updateProspectDrawerTracking={updateProspectDrawerTracking}
							isNewProspectDrawerEnabled={isNewProspectDrawerEnabled}
							conversation={conversation}
							contactConnection={contactConnection}
							orgId={orgId}
							tab={tab}
							orgMember={orgMember}
							selectedConnection={selectedConnection}
							conversationId={conversationId}
							setContactTags={setContactTags}
							setSelectedMemberId={setSelectedMemberId}
							contactProfile={contactProfile}
							onSaveTags={onSaveTags}
							isOwner={isOwner}
							automatedSteps={automatedSteps}
							onMessageSent={() => setHasSentMessage(true)}
						/>
					</ErrorBoundary>
				</ContactDrawerLayout.RightPanel>
			</ContactDrawerLayout>
		</StaticBaseDrawer>
	);
}

export default ContactDrawer;
