import React from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router';
import BaseLayout from '@copilot/common/pages/layouts/base';
import RouteWithPermissions from '@copilot/common/router/routeWithPermissions';
import LoginLayout from '@copilot/common/pages/layouts/login';
import LoginPage from '@copilot/common/pages/login';
import BaseSidebar from '../components/menus/sidebar';
import { RoleGroups } from '@copilot/common/config/roles';
import PlainLayout from '@copilot/common/pages/layouts/plain';
import PasswordRecoveryPage from '@copilot/common/pages/passwordRecovery';
import AppSnippets from '@copilot/common/components/snippets/app';
import withIndividualUserType from '@copilot/common/hoc/userType/individual';
import BaseHeaderContainer from '@copilot/cs/src/components/menus/header';
import { Config } from '@copilot/common/config';
import { EnvironmentType } from '@copilot/cs/src/components/menus/header/constant';
import NurtureOnboardWizard from '@copilot/common/pages/wizard/nurtureOnboard';
import { withSuspense } from '@copilot/common/hoc/components';
import {
	createCampaignsContainerWithConfig,
	withIndividualCapability,
} from '@copilot/common/pages/campaigns/ui/campaignsContainer';
import { SentMessageContainer } from '@copilot/common/pages/sent/ui/container';
import {
	createCampaignDashboardWithRedirect,
	withIndividualRedirect,
} from '@copilot/common/pages/campaignDashboard';
import { MOUSEFLOW_APP_ID } from '@copilot/common/components/snippets/const';
import RegistrationPage from '@copilot/common/pages/registration';
import ScrollToTop from '@copilot/common/router/scrollToTop';
import { NewUserOnboardPage } from '../../../common/pages/campaignDashboard/newUserOnboard';
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
} from '@copilot/common/utils/authenticationProvider';
import B2CSignUpPage from '@copilot/common/pages/b2csignup';

const settingsPagePromise = import('@copilot/common/pages/settings/settingsPage');
const connectionsPagePromise = import('@copilot/common/pages/connections');
const outboxPagePromise = import('@copilot/common/pages/outbox');
const referralPagePromise = import('@copilot/common/pages/referral');
const dashboardPromise = import('@copilot/common/pages/dashboard');

const SettingsPage = React.lazy(() => settingsPagePromise);
const ConnectionsPage = React.lazy(() => connectionsPagePromise);
const OutboxPage = React.lazy(() => outboxPagePromise);
const ReferralPage = React.lazy(() => referralPagePromise);
const Dashboard = React.lazy(() => dashboardPromise);

const BaseLayoutRoutes = ['/', '/inbox', '/settings', '/upgrade'];
const LoginLayoutRoutes = ['/login', '/passwordrecovery', '/registration/passwordconfirmation'];
const SignUpLayoutRoutes = ['/signup'];
const PlainLayoutRoutes = ['/onboard', '/wizard'];
const roleReq = RoleGroups.Member;
const CampaignDashComponent = withIndividualUserType(
	createCampaignDashboardWithRedirect(withIndividualRedirect)
);

const CampaignsPage = createCampaignsContainerWithConfig(withIndividualCapability);
const Router: React.FC<RouteProps> = (props) => (
	<>
		<ScrollToTop />
		<Switch location={props.location}>
			{Config.isB2CEnabled && (
				<Route exact path={SignUpLayoutRoutes}>
					<LoginLayout>
						<Switch>
							<RouteWithPermissions
								exact
								path="/signup"
								render={(renderProps) => (
									<B2CSignUpPage {...renderProps} isIndividualUser />
								)}
								allowedRoles={RoleGroups.Guest}
								roleRedirectTo="/"
							/>
						</Switch>
					</LoginLayout>
				</Route>
			)}
			<Route exact path={LoginLayoutRoutes}>
				<UnauthenticatedTemplate>
					<LoginLayout>
						<Switch>
							<RouteWithPermissions
								exact
								path="/registration/passwordconfirmation"
								component={RegistrationPage}
								allowedRoles={RoleGroups.Guest}
								roleRedirectTo="/"
							/>
							<RouteWithPermissions
								exact
								path="/login"
								component={LoginPage}
								allowedRoles={RoleGroups.Guest}
								roleRedirectTo="/"
							/>
							<RouteWithPermissions
								exact
								path="/passwordrecovery"
								render={(renderProps) => (
									<PasswordRecoveryPage {...renderProps} isIndividualUser />
								)}
								allowedRoles={RoleGroups.Guest}
								roleRedirectTo="/login"
							/>
						</Switch>
					</LoginLayout>
				</UnauthenticatedTemplate>
			</Route>
			<Route path={PlainLayoutRoutes}>
				<AuthenticatedTemplate>
					<PlainLayout>
						<Switch>
							<RouteWithPermissions
								exact
								path="/onboard"
								component={NewUserOnboardPage}
								allowedRoles={roleReq}
							/>
						</Switch>
						<Switch>
							<RouteWithPermissions
								exact
								path="/wizard/nurtureOnboard/:campaignId"
								component={NurtureOnboardWizard}
								allowedRoles={roleReq}
							/>
						</Switch>
					</PlainLayout>
				</AuthenticatedTemplate>
			</Route>
			<Route path={BaseLayoutRoutes}>
				<AuthenticatedTemplate>
					<BaseLayout>
						<BaseLayout.Sidebar>
							<BaseSidebar />
						</BaseLayout.Sidebar>
						<BaseLayout.Header>
							<BaseHeaderContainer
								environmentType={EnvironmentType.INDIVIDUAL}
								showProfile
								showCalendly={false}
								showSettings={false}
							/>
						</BaseLayout.Header>{' '}
						<BaseLayout.Content>
							<Switch>
								<RouteWithPermissions
									exact
									path="/"
									component={withSuspense(Dashboard)}
									allowedRoles={roleReq}
								/>
								<RouteWithPermissions
									exact
									path="/campaigns"
									component={CampaignsPage}
									allowedRoles={roleReq}
								/>
								<RouteWithPermissions
									exact
									path="/campaign/:campaignId/:tab?"
									allowedRoles={roleReq}
									component={CampaignDashComponent}
								/>
								<RouteWithPermissions
									path="/settings/:tab?"
									component={withSuspense(SettingsPage)}
									allowedRoles={roleReq}
								/>
								{!Config.isAgency && (
									<RouteWithPermissions
										exact
										path="/referral"
										component={withSuspense(ReferralPage)}
										allowedRoles={roleReq}
									/>
								)}
								<RouteWithPermissions
									exact
									path="/sent"
									component={SentMessageContainer}
									allowedRoles={roleReq}
								/>
								<RouteWithPermissions
									exact
									path="/outbox"
									component={withSuspense(OutboxPage)}
									allowedRoles={roleReq}
								/>
								<RouteWithPermissions
									exact
									path="/connections/:connectionId?"
									component={withSuspense(ConnectionsPage)}
									allowedRoles={roleReq}
								/>
								<Redirect
									to={{
										pathname: '/',
										state: {
											redirectStatus: true,
										},
									}}
								/>
							</Switch>
						</BaseLayout.Content>
					</BaseLayout>
				</AuthenticatedTemplate>
			</Route>
		</Switch>
		<AppSnippets isTeamUser={false} mouseflowAppId={MOUSEFLOW_APP_ID} />
	</>
);
export default Router;
