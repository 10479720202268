import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import Theme from '@copilot/common/config/antd.theme';
import Router from './router';
import Store from './store';
import DataInitializer from '@copilot/common/utils/dataInitializer';
import { PermissionsProvider } from '@copilot/common/hooks/permission';
import AppDataInitializer from '@copilot/common/utils/dataInitializer/app';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { appInsights } from '@copilot/common/components/snippets/applicationInsights';
import { IntercomProvider } from 'react-use-intercom';
import { AntdTheme, Config } from '@copilot/common/config';
import ErrorBoundary from '@copilot/common/components/containers/errorBoundary';
import LogRocketProvider from '@copilot/common/components/snippets/logRocket';
import AppcuesProvider from '@copilot/common/components/snippets/appcues';
import AuthenticationProvider from '@copilot/common/utils/authenticationProvider';

ReactDOM.render(
	<Provider store={Store.ReduxStore}>
		<ConnectedRouter history={Store.History}>
			<ConfigProvider prefixCls="copilot" theme={AntdTheme}>
				<ThemeProvider theme={Theme}>
					<AppInsightsContext.Provider value={appInsights.reactPlugin}>
						<IntercomProvider appId={Config.intercomAppId} autoBoot>
							<AuthenticationProvider>
								<AppDataInitializer>
									<PermissionsProvider>
										<DataInitializer isTeamUser={false}>
											<LogRocketProvider
												appId={Config.logRocketAppId}
												isEnabled={Config.isLogRocketEnabled}
											>
												<AppcuesProvider
													appId={Config.appcuesAppId}
													isEnabled={Config.isAppcuesEnabled}
												>
													<ErrorBoundary message="Something went wrong. Please refresh the page and try again">
														<Router />
													</ErrorBoundary>
												</AppcuesProvider>
											</LogRocketProvider>
										</DataInitializer>
									</PermissionsProvider>
								</AppDataInitializer>
							</AuthenticationProvider>
						</IntercomProvider>
					</AppInsightsContext.Provider>
				</ThemeProvider>
			</ConfigProvider>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root')
);
