import { Button, Select, Space, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import { ContactAvatar } from '@copilot/common/components/componentModels/activities/conversationWrapper/item/contact-avatar';
import { isEmpty } from 'lodash';
import AntIcon, { LinkedinFilled } from '@ant-design/icons';
import { URL } from '@copilot/common/data/urls';
import { useProspectDrawerTracking } from '@copilot/common/components/drawer/wrappers/contact/tracking';
import styles from './header.module.less';
import {
	HEADER_CONNECTION_SELECTOR_TEST_ID,
	HEADER_LINKEDIN_BUTTON_TEST_ID,
	HEADER_SECTION_WRAPPER_TEST_ID,
} from '../../contactDrawerV2/infoPanelV2/constants';

type HeaderProps = {
	firstName: string;
	lastName: string;
	jobTitle: string;
	company: string;
	selectedConnectedOption: string;
	handleConnectedToSelect: (selectedValue: string) => void;
	connectedToOptions: { label: string; value: string }[];
	isOpenProfile?: boolean;
	contactAlias?: string;
};

const { Paragraph } = Typography;
/**
 * Takes a LinkedIn alias and returns a profile URL.
 * @param alias
 */
function generateLinkedInProfileUrl(alias: string): string {
	return `${URL.LINKEDIN_URL}/in/${alias}`;
}

/**
 * Icon for the prospect not connected
 * @constructor
 */
function NotConnectedIcon() {
	return (
		<svg
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle id="Head" cx="5" cy="5" r="4" stroke="#FF4D4F" stroke-width="2" />
		</svg>
	);
}

/**
 * Presentational component for header on meetings booked
 * @returns
 */
export default function Header({
	firstName,
	lastName,
	jobTitle,
	company,
	contactAlias,
	connectedToOptions,
	selectedConnectedOption,
	handleConnectedToSelect,
}: HeaderProps) {
	const updateProspectDrawerTracking = useProspectDrawerTracking('Prospect Drawer');

	return (
		<Space direction="vertical" className={styles.headerWrapper}>
			<div className={styles.headerInfoSection} data-testid={HEADER_SECTION_WRAPPER_TEST_ID}>
				<ContactAvatar contactName={firstName} type="contact" />
				<div className={styles.nameJob}>
					<Title level={3} ellipsis={{ tooltip: true }} className={styles.nameTitle}>
						{`${firstName} ${lastName}`}
					</Title>
					{!isEmpty(jobTitle) && !isEmpty(company) ? (
						<Paragraph
							ellipsis={{ rows: 2, expandable: false, tooltip: true }}
							className={styles.jobTitle}
						>
							{`${jobTitle} @ ${company}`}
						</Paragraph>
					) : null}
				</div>
				{!isEmpty(contactAlias) ? (
					<Button
						data-testid={HEADER_LINKEDIN_BUTTON_TEST_ID}
						className={styles.linkedInButton}
						icon={<LinkedinFilled />}
						href={generateLinkedInProfileUrl(String(contactAlias))}
						target="_blank"
						onClick={() =>
							updateProspectDrawerTracking({
								buttonClicked: 'View on LinkedIn Button',
							})
						}
					/>
				) : null}
			</div>
			{isEmpty(connectedToOptions) ? (
				<Space>
					<AntIcon component={NotConnectedIcon} />
					<span className={styles.noConnectionText}>
						This prospect isn't a connection yet
					</span>
				</Space>
			) : null}
			{connectedToOptions.length == 1 && (
				<div className={styles.singleConnectionWrapper}>
					<span>Connected to&nbsp;</span>
					<span className={styles.connectedToText}>{connectedToOptions[0].label}</span>
				</div>
			)}
			{connectedToOptions.length > 1 && (
				<div className={styles.connectedToWrapper}>
					<div className={styles.multiConnectTitle}>Connected to</div>
					<Select
						className={styles.connectedToSelect}
						defaultValue={selectedConnectedOption}
						onChange={(value) => handleConnectedToSelect(value)}
						data-testid={HEADER_CONNECTION_SELECTOR_TEST_ID}
					>
						{connectedToOptions.map((contact) => (
							<Select.Option value={contact.value} key={contact.value}>
								{contact.label}
							</Select.Option>
						))}
					</Select>
				</div>
			)}
		</Space>
	);
}
