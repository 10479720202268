import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React from 'react';
import styled from 'styled-components';

interface IRadioCircleProps {
	checked: boolean;
	size?: SizeType;
}

const BigCircle = styled.div`
	${(props) => `
        border-radius: 50%;
        position: relative;
        border:1px solid rgba(0, 0, 0, 0.25);
        width:30px;
        height:30px;
    
        &.checked {
            border-color: ${props.theme['@primary-color']};
    
            div{
                background-color:${props.theme['@primary-color']};
            }
    
        }
		`}
`;

const SmallCircle = styled.div`
	position: absolute;
	border-radius: 50%;
	height: 15px;
	width: 15px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

/**
 * Component to reflect a custom styled radio button
 * @param checked whether the radio button is in a checked state
 * @param size size of radio button
 */
const RadioCircle: React.FC<IRadioCircleProps> = (props) => {
	const { checked, size } = props;
	let bigCircleSize = '';
	let smallCircleSize = '';

	switch (size) {
		case 'large':
			bigCircleSize = '40px';
			smallCircleSize = '20px';
			break;
		case 'small':
			bigCircleSize = '20px';
			smallCircleSize = '10px';
			break;
		default:
			break;
	}
	return (
		<BigCircle
			className={checked ? 'checked' : "'"}
			style={{ width: bigCircleSize, height: bigCircleSize }}
		>
			<SmallCircle style={{ width: smallCircleSize, height: smallCircleSize }} />
		</BigCircle>
	);
};

export default RadioCircle;
