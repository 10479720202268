import React, { useCallback, useState, useMemo } from 'react';
import { Col, Divider, Row, Button } from 'antd';
import MessageEditorWithTemplates from '@copilot/common/components/editors/message/withTemplates';
import MessageEditor from '@copilot/common/components/editors/message';
import {
	NodeTimerPeriodEnum,
	NodeTimerPeriodStringEnum,
} from '@copilot/common/store/models/const/enum';
import { DEFAULT_TIME_UNITS, Template } from '@copilot/common/pages/settings/message/const';
import { OnboardMessage } from '@copilot/data/requests/models';
import { getMessageTemplates } from '@copilot/common/utils/message';

interface IFollowUpMessageElementProps {
	message: OnboardMessage;
	templates?: Template[];
	selectedTemplate?: string;
	isLastMessage: boolean;
	label: string;
	time: number;
	period: NodeTimerPeriodEnum;
	timeUnits?: NodeTimerPeriodStringEnum[];
	hideTiming: boolean;
	handleFollowupUpdate: (originalMessage: OnboardMessage) => (newMessage: OnboardMessage) => void;
	handleFollowupRemove: (message: OnboardMessage) => void;
	handleTemplateUpdate?: (template: string) => void;
}

const FollowUpMessageElement: React.FC<IFollowUpMessageElementProps> = (props) => {
	const {
		message,
		templates,
		isLastMessage,
		label,
		time,
		period,
		timeUnits,
		hideTiming,
		handleFollowupUpdate,
		handleFollowupRemove,
	} = props;

	const [_selectedTemplate, _setSelectedTemplate] = useState<string>('');

	const { selectedTemplate, handleTemplateUpdate } = useMemo(
		() =>
			props.selectedTemplate !== undefined && props.handleTemplateUpdate
				? {
						selectedTemplate: props.selectedTemplate,
						handleTemplateUpdate: props.handleTemplateUpdate,
				  }
				: {
						selectedTemplate: _selectedTemplate,
						handleTemplateUpdate: _setSelectedTemplate,
				  },
		[
			props.selectedTemplate,
			props.handleTemplateUpdate,
			_selectedTemplate,
			_setSelectedTemplate,
		]
	);

	const onFollowupRemove = useCallback(() => {
		handleFollowupRemove(message);
		handleTemplateUpdate?.(''); // reset template
	}, [message, handleFollowupRemove, handleTemplateUpdate]);

	return (
		<React.Fragment key={label}>
			<Row style={{ alignItems: 'center' }}>
				<Col span={21}>
					<h2>{label} Message</h2>
				</Col>
				{isLastMessage && (
					<Col span={3}>
						<Button onClick={onFollowupRemove} style={{ float: 'right' }}>
							Remove
						</Button>
					</Col>
				)}
			</Row>
			<Row>
				<Col>
					{templates ? (
						<MessageEditorWithTemplates
							message={message}
							templates={templates}
							onUpdate={handleFollowupUpdate(message)}
							recommendedTime={time}
							recommendedPeriod={period}
							timeUnits={timeUnits}
							hideTiming={hideTiming}
							selectedTemplate={selectedTemplate}
							updateSelectedTemplate={handleTemplateUpdate}
						/>
					) : (
						<MessageEditor
							message={message}
							onUpdate={handleFollowupUpdate(message)}
							recommendedTime={time}
							recommendedPeriod={period}
							timeUnits={timeUnits}
							hideTiming={hideTiming}
						/>
					)}
				</Col>
			</Row>
			<br />
			<Divider />
			<br />
		</React.Fragment>
	);
};

interface IOnboardMessageEditorProps {
	messages: OnboardMessage[];
	defaultSetting: { label: string; time: number; period: NodeTimerPeriodEnum }[];
	templates?: { [k: string]: { [t: string]: string } };
	selectedTemplates?: string[];
	onUpdate: (messages: IOnboardMessageEditorProps['messages']) => void;
	updateSelectedTemplate?: (messageIndex: number, template: string) => void;
	handleHideTiming?: (idx: number) => boolean;
	timeUnits?: NodeTimerPeriodStringEnum[];
}

/**
 * Onboard Message Editor
 * @param {OnboardMessage[]} messages saved messages during onboarding
 * @param {{label: string; time: number; period: NodeTimerPeriodEnum}[]} defaultSetting a list of new messages with default setting in order
 * @param {{ key: string; label: string; value: string }[]} [templates] message templates
 * @param {string[]} selectedTemplates array of templates that are selected, if using controlled templates
 * @callback onUpdate called on message updates
 * @callback updateSelectedTemplate called on selected template update, if using controlled templates
 * @callback [handleHideTiming] called when determining whether to hide timing editor or not
 * @param {NodeTimerPeriodStringEnum[]} [timeUnits] a list of time units to display in editor
 */

const OnboardMessageEditor: React.FC<IOnboardMessageEditorProps> = (props) => {
	const {
		messages,
		defaultSetting,
		templates,
		selectedTemplates,
		onUpdate,
		updateSelectedTemplate,
		handleHideTiming = () => false,
		timeUnits = DEFAULT_TIME_UNITS,
	} = props;

	const handleFollowupAdd = useCallback(() => {
		onUpdate([
			...messages,
			{
				period: defaultSetting[messages.length].period,
				text: '',
				time: defaultSetting[messages.length].time,
			},
		]);
	}, [messages]);

	const handleFollowupRemove = useCallback(
		(message) => {
			onUpdate(messages.filter((f) => f !== message));
		},
		[messages]
	);

	const handleFollowupUpdate = useCallback(
		(originalMessage) => (newMessage: OnboardMessage) => {
			const index = messages.findIndex((m) => m === originalMessage);
			if (index < 0) return;
			onUpdate([...messages.slice(0, index), newMessage, ...messages.slice(index + 1)]);
		},
		[messages]
	);

	return (
		<>
			{messages.map((message, idx) => (
				<FollowUpMessageElement
					key={idx}
					message={message}
					templates={getMessageTemplates(idx, templates)}
					selectedTemplate={selectedTemplates?.[idx]}
					isLastMessage={idx === messages.length - 1}
					label={defaultSetting[idx].label}
					time={defaultSetting[idx].time}
					period={defaultSetting[idx].period}
					timeUnits={timeUnits}
					hideTiming={handleHideTiming(idx)}
					handleFollowupUpdate={handleFollowupUpdate}
					handleFollowupRemove={handleFollowupRemove}
					handleTemplateUpdate={(template: string) =>
						updateSelectedTemplate?.(idx, template)
					}
				/>
			))}
			<Button onClick={handleFollowupAdd} disabled={messages.length >= defaultSetting.length}>
				+ Add follow-up message
			</Button>
		</>
	);
};

export default OnboardMessageEditor;
