import React, { useContext } from 'react';
import styled from 'styled-components';
import { isReactElement } from '@copilot/common/utils';
import WizardStep from '@copilot/common/components/wizard/steps';
import { FullPageWizardContext } from '@copilot/common/components/wizard/context';
import Wizard, { IWizardStaticProps, IWizardProps } from '@copilot/common/components/wizard';

interface IFullPageWizardHeaderProps {}
const WizardHeader: React.FC<IFullPageWizardHeaderProps> = ({ children }) => {
	const { isSaved, onSave } = useContext(FullPageWizardContext);
	return (
		<>
			{isReactElement(children) &&
				React.cloneElement(children, {
					isSaved,
					onSaveClicked: () => onSave(true),
				})}
		</>
	);
};

const ContentWrapper = styled.div`
	margin-top: ${(props) => props.theme['@layout-header-height']};
`;

const getHeaderAndContent = (children: React.ReactNode) => {
	let header: React.ReactNode = null;
	const content: React.ReactNode[] = [];
	React.Children.map(children, (child) => {
		if (isReactElement(child)) {
			switch (child.type) {
				case WizardHeader:
					header = child;
					break;
				case WizardStep:
					content.push(child);
					break;
			}
		}
	});
	return { header, content };
};

interface IFullPageWizardStaticProps extends IWizardStaticProps {
	Header: typeof WizardHeader;
}

interface IFullPageWizardProps extends IWizardProps {
	isSaved: boolean;
}

/**
 * Full-page Wizard component that takes in a header component
 */
const FullPageWizard: React.FC<IFullPageWizardProps> & IFullPageWizardStaticProps = (props) => {
	const { isSaved, onSave, children } = props;
	const { header, content } = getHeaderAndContent(children);

	return (
		<FullPageWizardContext.Provider value={{ isSaved, onSave }}>
			{header}
			<ContentWrapper>
				<Wizard
					{...props}
					style={{ minHeight: '100vh' }}
					sidebarStyle={{ backgroundColor: 'white' }}
					innerLayoutStyle={{ minWidth: '800px' }}
				>
					{content}
				</Wizard>
			</ContentWrapper>
		</FullPageWizardContext.Provider>
	);
};

FullPageWizard.Header = WizardHeader;
FullPageWizard.Step = WizardStep;

export default FullPageWizard;
