import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import Intercom from '../intercom';
import Mouseflow from '../mouseflow';
import { appInsights } from '../applicationInsights';
import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import { AuthenticationManager } from '@copilot/data';
import { useLocation } from 'react-router';
import { Config } from '@copilot/common/config';
import { AgencyCode } from '@copilot/common/config/constant';
import HubSpot from '../hubspot';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import { RootState } from '../../../store/reducers/index';
import { Userpilot } from 'userpilot';

interface AppSnippetsProps {
	isTeamUser: boolean;
	mouseflowAppId: string;
	userpilotAppKey?: string;
}

const AllowedRoles = [
	OrgRoleTitles.Admin,
	OrgRoleTitles.User,
	OrgRoleTitles.Advanced,
	OrgRoleTitles.Owner,
];

const AppSnippets: React.FC<AppSnippetsProps> = ({ mouseflowAppId, userpilotAppKey }) => {
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const activeImpersonator = useSelector((state: RootState) => state.app.impersonator);
	const location = useLocation();
	const roleAllowed = useMemo(
		() =>
			!!activeMember?.orgRoles?.filter((r) => AllowedRoles.includes(r as OrgRoleTitles))
				?.length,
		[activeMember?.orgRoles]
	);

	const canEnableUserpilot = isString(userpilotAppKey) && Config.isUserpilotEnabled;

	// Setup Userpilot - run once on component creation
	useEffect(() => {
		if (!canEnableUserpilot) return;
		Userpilot.initialize(userpilotAppKey);
	}, [canEnableUserpilot]);

	// Identify active user
	useEffect(() => {
		if (!canEnableUserpilot || isNil(activeMember)) return;
		Userpilot.identify(activeMember.userId, {
			firstName: activeMember.firstName,
			lastName: activeMember.lastName,
			email: activeMember.email,
			impersonator: activeImpersonator ?? '',
		});
	}, [activeMember?.userId]);

	useEffect(() => {
		if (roleAllowed && appInsights.ApplicationInsights && activeMember?.userId)
			appInsights.ApplicationInsights.setAuthenticatedUserContext(
				activeMember.userId,
				activeMember.organizationId,
				true
			);
	}, [activeMember?.userId, roleAllowed]);

	useEffect(() => {
		if (activeMember?.id) AuthenticationManager.callIdentify();
	}, [activeMember?.id, location.pathname]);

	if (!Config.isAgency) {
		return (
			<>
				<Intercom />
				<Mouseflow appID={mouseflowAppId} />
				<HubSpot />
			</>
		);
	} else if (Config.agencyCode === AgencyCode.cleverly) {
		return <Intercom />;
	} else {
		return <></>;
	}
};

export default AppSnippets;
