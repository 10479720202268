import React from 'react';
import { Col, Row, Layout, Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import UserDisplayWithDropdown from '@copilot/common/components/menus/user';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import styled from 'styled-components';
import LightLogo from '@copilot/common/assets/images/emailsig.png';
import DarkLogo from '@copilot/common/assets/images/copilotai_white.png';
import SaveStatusButton from '@copilot/common/components/button/saveStatus';
import SupportDropdown from '@copilot/cs/src/components/menus/header/support';
import { Config } from '@copilot/common/config';

const LayoutHeader = styled(Layout.Header)`
	&& {
		background-color: ${(props) => `${props.color}`};
		box-shadow: 0 0 10px 0 #00000026;
		z-index: 6;
		position: fixed;
		width: 100%;
		padding-left: 15px;
		top: 0;
		left: 0;
	}
`;

const StyledDivider = styled(Divider)`
	border-left: 1px solid #00000040;
	height: 26px;
`;

// TODO: get correct logos and text for whitelabel
const LogoImage = styled.img`
	display: block;
	padding: 6px 0;
	height: ${(props) => props.theme['@layout-header-height']};
`;

interface LogoHeaderProps {
	activeMember: IOrganizationMember;
	showSaved?: boolean;
	isSaved?: boolean;
	onSaveClicked?: () => void;
	onCloseClicked?: () => void;
	backgroundColor: string;
	logo: string;
	textColor: string;
	showSupport: boolean;
	isHelpPortalDisabled: boolean;
	isReportBugDisabled: boolean;
	openHelpPortal: string;
}

/**
 * Closeable Header Bar for Onboard Wizards
 * @param {IOrganizationMember} activeMember current member
 * @param {string} title header bar title
 * @param {boolean} showSaved whether we want to display the saved status
 * @param {boolean} isSaved save status
 * @param {function} onSaveClicked called when save is clicked
 * @param {function} onCloseClicked called when close button is clicked
 */

const LogoHeader: React.FC<LogoHeaderProps> = (props) => {
	const {
		activeMember,
		showSaved = false,
		isSaved,
		onSaveClicked,
		onCloseClicked,
		backgroundColor,
		logo,
		textColor,
		showSupport,
		isHelpPortalDisabled,
		isReportBugDisabled,
		openHelpPortal,
	} = props;

	return (
		<LayoutHeader color={backgroundColor}>
			<Row>
				{onCloseClicked && (
					<>
						<Col>
							<CloseOutlined
								style={{ fontSize: '22px', padding: '14px 0' }}
								onClick={onCloseClicked}
							/>
						</Col>
						<Col style={{ marginLeft: '4px', marginRight: '-4px' }}>
							<StyledDivider type="vertical" />
						</Col>
					</>
				)}
				<Col>
					<LogoImage src={logo} />
				</Col>
				<Col flex={1} />
				{showSaved && (
					<Col style={{ display: 'flex' }}>
						<SaveStatusButton isStateSaved={!!isSaved} onSaveClicked={onSaveClicked} />
					</Col>
				)}
				{showSupport && (
					<Col
						style={{
							color: textColor,
							cursor: 'pointer',
						}}
					>
						<SupportDropdown
							isHelpPortalDisabled={isHelpPortalDisabled}
							isReportBugDisabled={isReportBugDisabled}
							onOpenHelpPortal={() => window.open(openHelpPortal)}
							onOpenSiteStatus={
								Config.siteStatusURL
									? () => window.open(Config.siteStatusURL)
									: undefined
							}
						/>
					</Col>
				)}
				<Col style={{ color: textColor }}>
					{activeMember && (
						<UserDisplayWithDropdown
							user={activeMember}
							admin={null}
							showProfile={false}
							showCalendly={false}
							showSettings={false}
						/>
					)}
				</Col>
			</Row>
		</LayoutHeader>
	);
};

export default LogoHeader;

type WithColorAndLogoProps = PartialBy<LogoHeaderProps, 'backgroundColor' | 'logo' | 'textColor'>;

const withColorAndLogo =
	(Component: React.FC<LogoHeaderProps>, color: string, logo: string, text: string) =>
	(props: WithColorAndLogoProps) =>
		<Component backgroundColor={color} logo={logo} textColor={text} {...props} />;

export const DarkHeader = withColorAndLogo(LogoHeader, '#041527', DarkLogo, 'white');

export const LightHeader = withColorAndLogo(LogoHeader, '#fff', LightLogo, 'black');

/* Note: withSupportLinks becomes a smart component, with the injection of Config; HoC's are allowed to be smart components, even if the component they take in is presentational */

const withSupportLinks =
	<
		TGenericProps extends {
			showSupport: boolean;
			isHelpPortalDisabled: boolean;
			isReportBugDisabled: boolean;
			openHelpPortal: string;
		}
	>(
		Component: React.FC<TGenericProps>
	) =>
	(
		props: Omit<
			TGenericProps,
			'showSupport' | 'isHelpPortalDisabled' | 'isReportBugDisabled' | 'openHelpPortal'
		>
	) =>
		(
			<Component
				showSupport={!Config.isAgency}
				isHelpPortalDisabled={!Config.helpPortalURL}
				isReportBugDisabled={!Config.intercomAppId}
				openHelpPortal={Config.helpPortalURL}
				{...(props as any)}
			/>
		);

export const DarkHeaderWithSupportLinks = withSupportLinks(DarkHeader);
