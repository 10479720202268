import { ComponentProps, useMemo, useState } from 'react';
import { CampaignConnectionModel } from '../model';
import Header from '@copilot/common/components/drawer/wrappers/contact/header';
import { Pipeline } from '@copilot/common/components/drawer/wrappers/contact/contactPipeline/contactPipeline';
import ProspectInfo from '@copilot/common/components/drawer/wrappers/contact/prospectInfo';
import { Collapse, Divider, Space, Typography } from 'antd';
import DropdownReminderSelector from '@copilot/common/components/selector/reminder/dropdownSelector/dropdownReminderSelector';
import { MeetingButton } from '../meetingButton';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { useNewProspectDrawerReportingAndHistory } from '../data/hooks';
import { PersonalizedInsightsButtonProps, ReminderProps } from './types';
import { parseCampaignHistory } from './util';
import CampaignHistoryRows from '@copilot/common/components/drawer/wrappers/contact/campaignHistoryRows';
import PersonalizedInsightsDrawerSection from '@copilot/common/components/drawer/wrappers/contact/informationPanel/personalizedInsightsDrawerSection';
import TagsRow from '@copilot/common/components/drawer/wrappers/contact/tags/tagsRow';
import { useSelector } from 'react-redux';
import { getAllOrgTags } from '@copilot/common/pages/organizationDashboard/tags/data/selectors';
import { SessionBoundModel } from 'redux-orm';
import { ContactConnection } from '@copilot/common/store/models/redux';
import styles from './infoPanel.module.less';
import { PipelineProps } from '../contactPipeline/types';

type Props = {
	/**
	 * Campaign connections that the contact is connected to
	 */
	campaignConnections: readonly CampaignConnectionModel[];
	/**
	 * The contact
	 */
	contact: {
		firstName: string;
		lastName: string;
	};
	/**
	 * tags fot this contact
	 */
	contactTags: string[];
	/**
	 * value of selected option
	 */
	selectedOption: string;
	/**
	 * callback for setting tags
	 */
	setContactTags: (tags: string[]) => void;
	/**
	 * contact connection
	 */
	contactConnection: SessionBoundModel<ContactConnection, {}> | null;
	/**
	 * callback upon connected options select
	 */
	handleMemberSelect: (selectedValue: string) => void;
	/**
	 * Contact prospect information
	 */
	prospectInfo: ComponentProps<typeof ProspectInfo>;
	/**
	 * the profile id of the contact, if this is undefined or empty string we should disble the perosnalized insights button
	 */
	contactAlias?: string;
	/**
	 * whether the current org member is the org owner
	 */
	isOwner: boolean;
	isOpenProfile?: boolean;
	/**
	 * Whether to show a meeting booked state for the button
	 */
	isMeetingBooked: boolean;
	/**
	 * Whether the meeting booked button should be loading
	 */
	isMeetingLoading: boolean;
	/**
	 * Callback after meeting booked is clocked
	 */
	handleMeetingClick: (isBooked: boolean) => void;
	/**
	 * props for set reminder functionality
	 */
	reminderProps: ReminderProps;
	/**
	 * Whether the set reminder button should be loading
	 */
	isReminderLoading: boolean;
	/**
	 * Props for pipeline view
	 */
	pipelineProps: PipelineProps;
	/**
	 * Props for personalized insights
	 */
	personalizedInsightsProps: PersonalizedInsightsButtonProps;
	/**
	 * Callback for saving tags
	 */
	onSaveTags: (tags: string[]) => void;
};

const { Title } = Typography;

/**
 * [Presentational] The left side-panel of the contact drawer that display the contact's information
 * @param props
 * @constructor
 */
export default function ContactDrawerInformationPanel({
	campaignConnections,
	contact,
	contactTags,
	selectedOption,
	isOwner,
	setContactTags,
	contactConnection,
	handleMemberSelect,
	prospectInfo,
	reminderProps: {
		reminderDate,
		useReminderFunctions: { setReminder, cancelReminder, setReminderClick },
	},
	contactAlias,
	pipelineProps: { hasMessaged, hasReplied, hasBooked },
	isReminderLoading,
	isMeetingBooked,
	isMeetingLoading,
	handleMeetingClick,
	isOpenProfile,
	personalizedInsightsProps,
	onSaveTags,
}: Props) {
	const isCampaignHistoryEnabled = useNewProspectDrawerReportingAndHistory();
	const campaignHistory = parseCampaignHistory(campaignConnections, selectedOption);
	const orgTags = useSelector(getAllOrgTags);
	const availableTags = useMemo(() => orgTags.data.map((t) => t.name), [orgTags]);
	const [inputVisible, setInputVisibility] = useState<boolean>(false);
	return (
		<div className={styles.infoPanelWrapper}>
			<Space direction="vertical" size="middle" className={styles.headerWrapper}>
				<Header
					firstName={contact.firstName}
					isOpenProfile={isOpenProfile}
					lastName={contact.lastName}
					company={prospectInfo.contactCompany.value}
					jobTitle={prospectInfo.contactTitle.value}
					selectedConnectedOption={selectedOption}
					handleConnectedToSelect={handleMemberSelect}
					connectedToOptions={campaignConnections.map((contactConnect) => ({
						label: contactConnect.orgMemberName,
						value: contactConnect.orgMemberId,
					}))}
					contactAlias={contactAlias}
				/>
				<Pipeline hasMessaged={hasMessaged} hasReplied={hasReplied} hasBooked={hasBooked} />
				<Space direction="vertical" className={styles.buttonWrapper}>
					<MeetingButton
						block
						size={'large'}
						isBooked={isMeetingBooked}
						handleMeetingClick={handleMeetingClick}
						loading={isMeetingLoading}
						className={styles.meetingButton}
					/>
					<DropdownReminderSelector
						selectedDate={reminderDate}
						onUpdate={setReminder}
						onCancel={cancelReminder}
						onSetReminderClick={setReminderClick}
						isReminderSet={!isNil(reminderDate)}
						buttonType="default"
						size={'large'}
						loading={isReminderLoading}
						buttonClass={styles.reminderButton}
						alignment={{ points: ['cl', 'cr'] }}
					/>
				</Space>
			</Space>
			<Divider className={styles.divider} />
			{personalizedInsightsProps.isShown && (
				<>
					<PersonalizedInsightsDrawerSection {...personalizedInsightsProps} />
					<Divider className={styles.divider} />
				</>
			)}
			<div>
				<Collapse
					ghost
					items={[
						{
							label: <Title className={styles.infoPanelTitle}>Prospect Info</Title>,
							children: <ProspectInfo {...prospectInfo} />,
						},
					]}
				/>
			</div>
			<Divider className={styles.divider} />
			{!isNil(contactConnection) && (
				<>
					<div className={styles.collapseWrapper}>
						<Collapse
							ghost
							items={[
								{
									label: <Title className={styles.infoPanelTitle}>Tags</Title>,
									children: (
										<TagsRow
											isOwner={isOwner}
											contactTags={contactTags}
											showInput={() => setInputVisibility(true)}
											availableTags={availableTags}
											setContactTags={setContactTags}
											onSaveTags={(tags) => {
												setInputVisibility(false);
												onSaveTags(tags);
											}}
											inputVisible={inputVisible}
										/>
									),
								},
							]}
						/>
					</div>
					<Divider className={styles.divider} />
				</>
			)}
			{isCampaignHistoryEnabled && !isEmpty(campaignHistory) && (
				<>
					<div>
						<Collapse
							ghost
							items={[
								{
									label: (
										<Title className={styles.infoPanelTitle}>
											Campaign History
										</Title>
									),
									children: (
										<CampaignHistoryRows campaignHistory={campaignHistory} />
									),
								},
							]}
						/>
					</div>
					<Divider className={styles.divider} />
				</>
			)}
		</div>
	);
}
