import { Button, Typography } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import styles from './threadNavigator.module.less';

type ThreadNavigatorProps = {
	currThreadIdx: number;
	numThreads: number;
	onGoToThread: (idx: number) => void;
};
/**
 * Component to navigate between threads while contact panel is open
 * @param param0
 * @returns
 */
export default function ThreadNavigator({
	currThreadIdx,
	numThreads,
	onGoToThread,
}: ThreadNavigatorProps) {
	return (
		<div className={styles.threadNavigatorWrapper}>
			<Button
				onClick={() => onGoToThread(currThreadIdx - 1)}
				icon={<ArrowLeftOutlined />}
				size={'small'}
				disabled={currThreadIdx === 0}
			/>{' '}
			<Typography.Text className={styles.threadNavigatorText}>
				{currThreadIdx + 1} of {numThreads}{' '}
			</Typography.Text>
			<Button
				onClick={() => onGoToThread(currThreadIdx + 1)}
				icon={<ArrowRightOutlined />}
				size={'small'}
				disabled={currThreadIdx === numThreads - 1}
			/>
		</div>
	);
}
