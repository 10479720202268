import useTracking from '@copilot/common/hooks/tracking';

export type SidebarCampaignClicks = 'All Campaigns' | 'Campaign' | 'New Campaign';

interface SidebarTrackingParams {
	buttonClicked: SidebarCampaignClicks;
}

export const useSidebarTracking = (
	componentName: string,
	params: SidebarTrackingParams | null
): ((newParams: SidebarTrackingParams) => void) => useTracking(componentName, 'side_bar', params);
